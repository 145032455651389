import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { db } from '../../firebase/firebase';
import "../../Styles/Styles.css";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { SwitchLabel } from '../../Tools/Index';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 5000,
    color: '#fff',
  },
}));



const SendEmail = (props) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAttach, setIsAttach] = useState(true);
  const classes = useStyles();
  const studentsEachMonthRef = db.collection('studentsEachMonth')

  // useEffect(()=>{ 
  // },[isLoading]);

  const inputMessage = useCallback((e) => {
    setMessage(e.target.value)
  }, [setMessage]);

  const inputSubject = useCallback((e) => {
    setSubject(e.target.value)
  }, [setSubject]);


  const handleClose = () => {
    props.onChange(false);
  };

  const Clear = () => {
    handleClose()
  }

  const handleToggle = () => {
    setIsAttach(!isAttach);
  }



  const handleSend = async (listId, student, context, title, isAttach) => {
    //  sendMail()
    setIsLoading(true)
    const AttachFile = isAttach;
    const subject = title;
    const message = context;
    const isMail = true;

    console.log(student);

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/sendEmail", {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ listId, student, subject, message, isMail, AttachFile })
    });
    const resData = await response.json();

    setIsLoading(false)

    console.log(resData.message);

    if (resData.message === 'success') {
      alert("Message Sent.");
      handleClose();
    } else if (resData.message === 'fail') {
      alert("Message failed to send.")
      handleClose();
    }
    upToDataBase(student)
  };

  const upToDataBase = async (student) => {
    let tempStudent = student
    const stuId = student.id;
    const listId = props.listId;

    tempStudent.isSent = "sent"
    console.log(tempStudent, listId)

    await studentsEachMonthRef.doc(listId).collection('students')
      .doc(stuId).set(tempStudent, { merge: true });


  }

  return (
    <div>

      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">Sending Email To...</DialogTitle>


        <form style={{ width: "80%" }}>
          <FormControl margin="normal" fullWidth>
            {props.student && (
              <InputLabel htmlFor="name">To:
                {" " + props.student.familyName + " "
                  + props.student.givenName + "(" + props.student.email + ")"}
              </InputLabel>
            )}
            <Input id="name" type="text" />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="title">Title</InputLabel>
            <Input id="title" type="text"
              onChange={inputSubject} />
          </FormControl>


          <SwitchLabel checked={isAttach} onChange={handleToggle} label={"Attach Invoice?"} />


          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="email">Message</InputLabel>
            <Input id="email" multiline rows={10}
              onChange={inputMessage} />
          </FormControl>



          <Button variant="contained" color="primary" size="medium" onClick={() => handleSend(props.listId, props.student, message, subject, isAttach)}>
            Send
          </Button>
        </form>

      </Dialog>

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
        <p> Now sending Email...</p>
      </Backdrop>
    </div>
  );
}

export default SendEmail;