import {signInAction,singOutAction} from "./action";
import {push} from 'connected-react-router';
import {auth, FirebaseTimestamp, db} from '../../firebase/firebase';

export const listenAuthState = () => {
  return async (dispatch) => {
      return auth.onAuthStateChanged(user => {
          if (user) {
               const uid = user.uid
              db.collection('users').doc(uid).get()
                  .then(snapshot => {
                      const data = snapshot.data()
                    
                      // Update logged in user state
                      dispatch(signInAction({
                          isSignedIn:true,
                          role: data.role,
                          uid: user.uid,
                          username: data.username,
                      }))
                  })
          } else {
              dispatch(push('/signin'))
          }
      })
  }
};

export const signIn =(email,password)=> {
  return async(dispatch) =>{
     if(email===""||password===""){
      alert("Required information is not filled, try again")
      return false
     }
      auth.signInWithEmailAndPassword(email,password)
       .then( result => {
         const user = result.user
         if (user){
            const uid = user.uid

            db.collection('users').doc(uid).get()
             .then(snapshot=>{
               const data = snapshot.data()
               dispatch(signInAction({
                 isSignedIn: true,
                 role:data.role,
                 uid: uid,
                 username: data.username
               }))
               dispatch(push("/"))
             })
         }
       }

       )}
}

export const signOut = ()=>{
  return async(dispatch)=>{
    auth.signOut()
    .then (()=>{
    dispatch(singOutAction())
    dispatch(push("/signIn"))
    })
  } 
}

export const signUp =(username,email,password,confirmpassword)=>{
 return async(dispatch) => {
   //validation
   if(username ===""||email ===""||password===""){
     alert('not filled all the information')
     return false
   }    
   if (password !==confirmpassword) {
     alert('password is not same as confirmation, try again')
     return false
   }
   return auth.createUserWithEmailAndPassword(email,password)
      .then( result =>{
          const user = result.user

          if(user) {
            const uid = user.uid
            const timestamp = FirebaseTimestamp.now()
            const userInitialDate ={
              created_at:timestamp,
              email:email,
              role: "customer",
              updated_at: timestamp,
              username:username
            }

            db.collection('users').doc(uid).set(userInitialDate)
            .then (()=>{
              dispatch(push("/"))
            })


          }
      })
 }}