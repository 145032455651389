import React from 'react';
import Button from '@material-ui/core/Button';





const PButton =(props) => {
  return (
    <div>
      <Button variant={props.variant}  color={props.color} onClick={()=>props.onClick()}> 
        {props.label}
      </Button>
    </div>
  );
}

export default PButton;