import * as Actions from "./action";
import initialState from "../Store/initialState";

export const studentsEachMonthReducer = (state= initialState.studentsEachMonth,action) =>{
  switch(action.type){
    
      case Actions.FETCH_STUDENTSEACHMONTH:
         return{
           ...state,
           list: [...action.payload]
         };
       
    default: 
      return state
  }
}