import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormatItalic } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import InvoicePdf from './InvoicePdf';
import SendEmail from './SendEmail';
import SendLine from './SendLine';
import UploadPaymentData from "./UploadPaymentData";
import Card from '@material-ui/core/Card';
import "../../Styles/Styles.css";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { red, green } from '@material-ui/core/colors';
import DeleteStu from "./DeleteStu";
import MenuIcon from "../../Tools/MenuIcon";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    padding: "5px",
  },

  inputClassName: {
    height: 20,
    fontSize: 10,
  },

  inputCprice: {
    height: 20,
    fontSize: 10,
    backgroundColor: "#aeb5bc",
    color: "black",
  },
  inputGeneral: {
    fontSize: 12,
    height: 40,
  },

  inputTotal: {
    fontStyle: FormatItalic,
  },

  inputTotalPaid: {
    fontStyle: FormatItalic,
    color: "white",
    backgroundColor: "#3d5afe"
  },

  textL: {
    width: 200
  },
  textS: {
    width: 110
  }
}
));

const StudentList = (props) => {
  const classes = useStyles();
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [editStudent, setEditStudent] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [students, setStudents] = useState(props.students)
  const [openLine, setOpenLine] = useState(false);

  useEffect(() => {
    console.log(props.students);
    setStudents(props.students)
  }, [props.students])


  const studentsEachMonthRef = db.collection("studentsEachMonth")


  const handleClickOpenInvoice = (data) => {
    setOpenInvoice(true);
    setEditStudent(data);
  }

  const handleClickOpenInput = (data) => {
    setOpenInput(true);
    setEditStudent(data);
  }

  const handleClickOpenEmail = (data) => {
    setOpenEmail(true);
    setEditStudent(data);
  }

  const handleClickOpenLine = (data) => {
    setOpenLine(true);
    setEditStudent(data);
  }
  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    setEditStudent(data);
  }




  const handleChange = (stu, name, courseId) => (event) => {

    let editedStu = stu;
    let newCourses = editedStu.course.map((item) => {
      if (item.id === courseId) {
        return { ...item, [name]: event.target.value };
      } else {
        return item;
      }
    }
    );



    editedStu.course = newCourses;

    console.log(editedStu);

    saveEditing(editedStu);
  }

  const saveEditing = (editedStu) => {
    const monthId = editedStu.monthId;
    const stuId = editedStu.id;

    let tempStu = students.map((stu) => {
      if (stu.id === editedStu) { return editedStu; }
      else { return stu; }
    })

    setStudents(tempStu);

    studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(editedStu, { merge: true })
      .then(() => {
      }).catch((error) => {
        console.log(error)
        throw new Error(error)
      })
  }

  return (

    <Card>
      {students.map((data, order) =>
        <form className={classes.root} key={order} >
          <TextField
            variant="outlined"
            value={data.familyNameJapanese + " " + data.givenNameJapanese}
            className={classes.textL}
            InputProps={{
              className: classes.inputGeneral,
            }}
            size="small" />


          <TextField
            value={data.grade}
            InputProps={{
              className: classes.inputGeneral,
            }}
            className={classes.textS}
            variant="outlined" size="small" />

          {data.course.map((course, index) =>
            <div key={data.id + index} >
              <TextField
                variant="outlined"
                InputProps={{
                  className: classes.inputCprice,
                }}
                // InputLabelProps={{
                //   shrink: true
                // }}
                type={"text"}
                name={"classname" + data.id + index}
                defaultValue={course.classname}
                onBlur={handleChange(data, "classname", course.id)}
              />


              <TextField
                variant="outlined"
                InputProps={{
                  className: classes.inputClassName,
                }}
                //  InputLabelProps={{
                //    shrink: true
                //  }}
                name={"cPrice"}
                defaultValue={course.cPrice}
                type={"number"}
                onBlur={handleChange(data, "cPrice", course.id)}
              />

            </div>
          )}

          <div>
            {(() => {
              if (data.isPaid !== "paid") {
                return (
                  <TextField
                    value={data.course.reduce((prev, cur) => {
                      if (cur.cPrice !== "") {
                        return prev + parseFloat(cur.cPrice, 10)
                      } else {
                        return prev
                      }
                    }, 0)}

                    variant="outlined"
                    size="small"
                    InputProps={{
                      className: classes.inputTotal,
                    }}
                    className={classes.text} />)
              } else {
                return (
                  <TextField
                    value={data.course.reduce((prev, cur) => {
                      if (cur.cPrice !== "") {
                        return prev + parseFloat(cur.cPrice, 10)
                      } else {
                        return prev
                      }
                    }, 0)}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      className: classes.inputTotalPaid,
                    }}
                    className={classes.text} />)
              }
            })()
            }
          </div>


          <div className="icon_design">
            <DescriptionOutlinedIcon onClick={() => {
              handleClickOpenInvoice(data)
            }} />
            <AttachFileOutlinedIcon onClick={() => {
              handleClickOpenInput(data)
            }} />

            {(() => {
              if (data.lineProfile) {
                return (
                  <>
                    <MenuIcon
                      isSent={data.isSent}
                      handleClickOpenLine={handleClickOpenLine}
                      handleClickOpenEmail={handleClickOpenEmail}
                      data={data}
                    />
                    <SendLine
                      open={openLine}
                      onChange={setOpenLine}
                      student={data}
                    />

                  </>

                )
              } else {
                if (data.isSent === "undelivered")
                  return (
                    <EmailOutlinedIcon
                      variant="outlined"
                      style={{ color: red[500] }}
                      onClick={() => { handleClickOpenEmail(data) }}
                    />
                  )
                else
                  return (
                    <EmailOutlinedIcon variant="outlined" style={{ color: green[500] }} onClick={() => { handleClickOpenEmail(data) }} />
                  )
              }
            })()}


            <DeleteOutlineIcon style={{ color: red[500] }} onClick={() => { handleClickOpenDelete(data) }} />
          </div>
        </form>
      )}


      <InvoicePdf open={openInvoice} onChange={setOpenInvoice} listId={props.listId} student={editStudent} />

      <UploadPaymentData open={openInput} onChange={setOpenInput}
        listId={props.listId}
        selectedMonth={props.selectedMonth}
        student={editStudent}
        students={props.allStudents}
      />

      <SendEmail open={openEmail} onChange={setOpenEmail}
        listId={props.listId}
        student={editStudent}
        selectedMonth={props.selectedMonth}
      />





      <DeleteStu open={openDelete}
        onChange={setOpenDelete}
        setStu={props.setStu}
        student={editStudent}
        students={props.students}
        allStudents={props.allStudents}
        setAllStu={props.setAllStu}
      />
    </Card>
  );
}

export default StudentList;
