import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import {pink} from '@material-ui/core/colors';
import { AreaChart, Area, YAxis,XAxis, CartesianGrid, Tooltip} from 'recharts';
import Compare from './Compare';
import Divider from '@material-ui/core/Divider';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';


const useStyles = makeStyles({
  root: {
    minWidth: 250,
    width: "400px",
    height:"300px",
    marginRight: 20,
    marginTop: 20,
    padding:5,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
  },

  data:{
   fontSize: 30,
   marginLeft:20,
  },
  pos: {
    marginBottom: 12,
  },
  
});


const MetersSales=(props)=>{
  const classes = useStyles();

  return(
   <Card className={classes.root}>

    
       <Typography className={classes.title} color="textSecondary" gutterBottom>
       {props.title}{" "}{props.content}{": "}{props.month}
        </Typography>
        
      
       
        <Divider variant="middle" component="li" />

       <div className='card_content'> 
        <MonetizationOnIcon style={{fontSize:40,color:pink["A400"]}}/>
        <Typography className={classes.data} color="textSecondary" gutterBottom>
         {parseInt(props.latest)}
        </Typography>
        <Typography>
          Baht
        </Typography>
        <Compare dif={props.dif}/>    
       </div>
     
        <AreaChart
          width={350}
          height={200}
          data={props.values}
          margin={{
            top: 0,
            right: 10,
            left:0,
            bottom: 20,
          }}
        >
           <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="month" interval="preserveEnd"/>
          <YAxis interval="preserveEnd" domain={["dataMin-5","dataMax+5"]}/>
          <Tooltip />
          <Area type="monotone" dataKey="sales" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
    </Card>
  )
}

export default MetersSales;