import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { db } from "../../firebase/firebase";


const ModalDeleteStudent = (props) => {


  const studentsRef = db.collection("students")

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleClickDeleteStudent = async () => {
    console.log(props.student);
    await studentsRef.doc(props.student.id).delete();
    let tempAllStu = props.allStu.filter(stu => stu.id !== props.student.id);
    console.log(tempAllStu)
    props.setAllStu(tempAllStu);
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete " + props.student.familyName + " " + props.student.givenName + " ?"} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This manupilation can not be redone, if you do not want this just stay away from here. if you really wanna do this, click Delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            cancel
          </Button>
          <Button onClick={() => { handleClickDeleteStudent() }} color="secondary" >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalDeleteStudent;