export const FETCH_STUDENTS = 'FETCH_STUDENTS';


export const fetchStudentsAction = (students) => {
  return {
       type:"FETCH_STUDENTS",
       payload: students
  }
}

export const DELETE_STUDENTS = 'DELETE_STUDENTS';

export const deleteStudentsAction = (students) => {
  return {
       type:"DELETE_STUDENTS",
       payload: students
  }
}