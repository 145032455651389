import React, { useState, useEffect } from 'react';
import '../../Styles/Styles.css';
import { makeStyles } from '@material-ui/core/styles';
import ModalWindow from './ModalWindow';
import { FirebaseTimestamp, db } from '../../firebase/firebase';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Dnd from "../Dnd/Dnd";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add';
import ModalNewClass from './ModalNewClass';
import DeleteClass from './DeleteClass';
import ModalTransfer from './ModalTransfer';

const useStyles = makeStyles((theme) =>
({
  root: {
    //  display: 'flex',
    // flexWrap: 'colomn',
    margin: theme.spacing(1),
    padding: "10px",
    width: "20%",
    // height: "60%",

  },

  root2: {
    margin: theme.spacing(1),
    padding: "10px",
    width: "18%",
    height: "10%",
  },
  backdrop: {
    zIndex: 20000,
    // color: '#fff',
  },
}));



const ClassEdit = () => {

  const transferRef = db.collection("transfer");
  const classesRef = db.collection("classes");

  const [lecs, setLecs] = useState(null);
  const [openModalClassEdit, setOpenModalClassEdit] = useState(false);
  const [openModalNewClass, setOpenModalNewClass] = useState(false);
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const [openCheckDelete, setOpenCheckDelete] = useState(false);
  const [transferFee, setTransferFee] = useState("");
  const [scaleFactor, setScaleFactor] = useState("")
  const [selectedClass, setSelectedClass] = useState({ id: "", classname: "", cPrice: "", isMain: false, isTemp: false, isDiscount: false })

  const scaleSamples = ['0.25', '0.50', '0.75', '1.00', '1.25', '1.50', '1.75', '2.00'];


  const fetchTransferRef = async () => {
    transferRef.get()
      .then(snapshot => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          setTransferFee(data.transferFee);
          setScaleFactor(data.scaleFactor);

        })
      })
  }

  const fetchClasses = async () => {
    let tempClasses = [];
    await classesRef.orderBy('order', 'asc').get()
      .then(snapshots => {
        snapshots.forEach((snapshot) => {
          const data = snapshot.data()
          tempClasses.push(data);
        })
      }
      )
    setLecs(tempClasses);
  }

  const handleOpenModalNewClass = () => {
    setOpenModalNewClass(true);
  }

  const handleOpenModalTransfer = () => {
    setOpenModalTransfer(true);
  }

  const handleScaleFactor = async () => {
    const indexNow = scaleSamples.indexOf(scaleFactor);
    const id = "transferFee";
    const tempScale = scaleSamples[(indexNow + 1) % 8]
    console.log(indexNow, tempScale);
    const data = {
      scaleFactor: tempScale
    }

    await transferRef.doc(id).set(data, { merge: true })
    setScaleFactor(tempScale);
  }

  // クラスに配列の順番を入れる
  const handleGiveOrder = async () => {
    if (lecs) {
      await lecs.forEach((lec, index) => {
        lec.order = index;
        classesRef.doc(lec.id).set(lec, { merge: true })
      })
    }
  }

  useEffect(() => {
    fetchTransferRef()
    fetchClasses()
  }, []);

  useEffect(() => {
    handleGiveOrder()
  }, [lecs])


  const classes = useStyles();



  if (!lecs) return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
      <p> now loading...</p>
    </Backdrop>
  )

  return (
    <section className="text_field">

      <div className="vertical_buttons">
        <div className="m-3">
          <Fab color="primary"
            onClick={handleOpenModalNewClass}
          >
            <AddIcon />
          </Fab>
        </div>


        <div className="m-3">
          <Fab color="primary"
            onClick={handleOpenModalTransfer}
          >
            <DirectionsBusIcon />
            <p>{transferFee}</p>
          </Fab>
        </div>

        <div className="m-3">
          <Fab color="primary"
            onClick={handleScaleFactor}
          >
            <ClearOutlinedIcon
              fontSize={'small'} />
            <p>{scaleFactor}</p>
          </Fab>
        </div>

        {/* 
        <div className="m-3">
          <Fab color="primary" aria-label="add">
            <AddIcon
              onClick={handleGiveOrder}
            />
          </Fab>
        </div> */}

      </div>


      <Dnd
        items={lecs}
        setItems={setLecs}
        scaleFactor={scaleFactor}
        setSelectedClass={setSelectedClass}
        setOpenModalClassEdit={setOpenModalClassEdit}

      />

      <ModalWindow
        open={openModalClassEdit}
        setOpen={setOpenModalClassEdit}
        setOpenCheckDelete={setOpenCheckDelete}
        content={selectedClass}
        lecs={lecs}
        setLecs={setLecs}

      />

      <ModalNewClass
        open={openModalNewClass}
        setOpen={setOpenModalNewClass}
        lecs={lecs}
        setLecs={setLecs}

      />

      <DeleteClass
        open={openCheckDelete}
        setOpen={setOpenCheckDelete}
        setOpenModalClassEdit={setOpenModalClassEdit}
        content={selectedClass}
        lecs={lecs}
        setLecs={setLecs}
      />

      <ModalTransfer
        open={openModalTransfer}
        setOpen={setOpenModalTransfer}
        transferFee={transferFee}
        setTransferFee={setTransferFee}
      />

    </section>


  )
}

export default ClassEdit;