import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../../Styles/Styles.css";
import {
    FormControl,
    InputLabel,
    Input,
    Button,
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { SwitchLabel } from '../../Tools/Index';
import { green } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 5000,
        color: '#fff',
    },
}));



const SendLine = (props) => {
    console.log(props.student)

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAttach, setIsAttach] = useState(true);
    const classes = useStyles();


    const inputMessage = useCallback((e) => {
        setMessage(e.target.value)
    }, [setMessage]);

    const handleClose = () => {
        props.onChange(false);
    };

    const Clear = () => {
        handleClose()
    }

    const handleToggle = () => {
        setIsAttach(!isAttach);
    }



    const handleSend = async (student, isAttach) => {
        //  sendMail()
        setIsLoading(true)
        const AttachFile = isAttach;


        if (message !== "") {
            const responseMessage = await fetch(process.env.REACT_APP_API_BASE_URL + "/sendLineMessage", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ student, message })
            });

            const resDataMessage = await responseMessage.json();
            console.log(resDataMessage);
        }

        if (AttachFile) {
            const responseInvoice = await fetch(process.env.REACT_APP_API_BASE_URL + "/sendLineInvoice", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ student })
            });

            const resData = await responseInvoice.json();
            console.log(resData);
        }

        setMessage("")
        setIsLoading(false);
        props.onChange(false);
    };


    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">Sending Line Message To...</DialogTitle>


                <form style={{ width: "80%" }}>
                    <FormControl margin="normal" fullWidth>
                        {props.student && (
                            <InputLabel htmlFor="name">To:
                                {" " + props.student.familyName + " " + props.student.givenName
                                    + "(" + props.student.lineProfile.displayName + ")"
                                }
                            </InputLabel>
                        )}
                        <Input id="name" type="text" />
                    </FormControl>


                    <SwitchLabel checked={isAttach} onChange={handleToggle} label={"Attach Invoice?"} />


                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="email">Message</InputLabel>
                        <Input id="email" multiline rows={10}
                            onChange={inputMessage} />
                    </FormControl>



                    <Button
                        variant="contained"
                        style={{ backgroundColor: green[400], color: 'white' }}
                        size="medium"
                        onClick={() => handleSend(
                            props.student, isAttach)}
                    >
                        Send Line
                    </Button>
                </form>

            </Dialog>

            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
                <p> Now sending Line massage...</p>
            </Backdrop>
        </div>
    );
}

export default SendLine;