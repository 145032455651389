import React from 'react';
import { Route, Switch } from 'react-router';
import { Home, ClassEdit, SignIn, SignUp, Students, StudentsBase, NewStudents } from './View/index';
import Auth from './Auth';

const Router = () => {
  return (
    <Switch>
      <Route exact path={"/signup"} component={SignUp} />
      <Route exact path={"/signin"} component={SignIn} />
      <Auth>
        <Route exact path={"(/)?"} component={Home} />
        <Route exact path={"/class"} component={ClassEdit} />
        <Route exact path={"/newStudents"} component={NewStudents} />
        <Route exact path={"/students"} component={StudentsBase} />
        <Route exact path={"/invoice"} component={Students} />
      </Auth>
    </Switch>
  )
}

export default Router;


