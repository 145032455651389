import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { TextInput, SelectBox, DatePickers, MultipleSelect } from '../../Tools/Index';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses } from '../../reducks/classes/selectors';
import { fetchClasses } from "../../reducks/classes/operations";
import { SwitchLabel } from "../../Tools/Index";
import { FirebaseTimestamp, db } from '../../firebase/firebase';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import BasicGrid from './BasicGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const ModalWindowNewStus = (props) => {

    const classes = useStyles();
    const [studentId, setStudentId] = useState(props.student.id);
    const [parentName, setParentName] = useState(props.student.parentName);
    const [gender, setGender] = useState(props.student.gender);
    const [grade, setGrade] = useState(props.student.grade);
    const [givenName, setGivenName] = useState(props.student.givenName);
    const [familyName, setFamilyName] = useState(props.student.familyName);
    const [familyNameJapanese, setFamilyNameJapanese] = useState(props.student.familyNameJapanese);
    const [givenNameJapanese, setGivenNameJapanese] = useState(props.student.givenNameJapanese);
    const [schoolName, setSchoolName] = useState(props.student.schoolName);
    const [birthDay, setBirthday] = useState(props.student.birthDay);
    const [address, setAddress] = useState(props.student.address);
    const [course, setCourse] = useState(props.student.course);
    const [pickUp, setPickUp] = useState(props.student.pickUp);
    const [pickUpSchedule, setPickUpSchedule] = useState(props.pickUpschedule);
    const [email, setEmail] = useState(props.student.email);
    const [phoneNumber, setPhoneNumber] = useState(props.student.phoneNumber);
    const [isSibling, setIsSibling] = useState(props.student.isSibling);

    const [open, setOpen] = useState(false);


    const inputParentName = useCallback((e) => {
        setParentName(e.target.value)
        setFamilyName(e.target.value)
    }, [setParentName]);

    const inputGivenName = useCallback((e) => {
        setGivenName(e.target.value)

    }, [setGivenName]);


    const inputFamilyNameJapanese = useCallback((e) => {
        setFamilyNameJapanese(e.target.value)
    }, [setFamilyNameJapanese]);

    const inputGivenNameJapanese = useCallback((e) => {
        setGivenNameJapanese(e.target.value)
    }, [setGivenNameJapanese]);

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail]);

    const inputPhoneNumber = useCallback((e) => {
        setPhoneNumber(e.target.value)
    }, [setPhoneNumber]);

    const inputAddress = useCallback((e) => {
        setAddress(e.target.value)
    }, [setAddress]);


    const toggleIsSibling = () => {
        setIsSibling(!isSibling)
    }

    const handleClickAction = () => {
        setOpen(true);
        console.log(open);
    }

    const handleClose = () => {
        props.onChange(false);
    };

    const handleCloseSchedule = () => {
        setOpen(false);
    }

    const genderOptions = [
        { id: "Mr.", name: "Mr." },
        { id: "Ms.", name: "Ms." }
    ]

    const gradeOptions = [
        { id: "年少", name: "年少" }, { id: "年中", name: "年中" }, { id: "年長", name: "年長" },
        { id: "小1", name: "小1" }, { id: "小2", name: "小2" }, { id: "小3", name: "小3" }, { id: "小4", name: "小4" }, { id: "小5", name: "小5" }, { id: "小6", name: "小6" },
        { id: "中1", name: "中1" }, { id: "中2", name: "中2" }, { id: "中3", name: "中3" },
        { id: "高1", name: "高1" }, { id: "高2", name: "高2" }, { id: "高3", name: "高3" }, { id: "クレ", name: "クレ" }
    ]

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const courses = getClasses(selector);
    const studentsRef = db.collection("students");
    const newStudentsRef = db.collection("newStudents");

    useEffect(() => {
        dispatch(fetchClasses())
        setStudentId(props.student.id);
        setParentName(props.student.parentName);
        setGender(props.student.gender);
        setGrade(props.student.grade);
        setGivenName(props.student.givenName);
        setFamilyName(props.student.familyName);
        setFamilyNameJapanese(props.student.familyNameJapanese);
        setGivenNameJapanese(props.student.givenNameJapanese);
        setSchoolName(props.student.schoolName);
        setBirthday(props.student.birthDay);
        setAddress(props.student.address);
        setCourse(props.student.course);
        setPickUp(props.student.pickUp);
        setPickUpSchedule(props.student.pickUpSchedule);
        setEmail(props.student.email);
        setPhoneNumber(props.student.phoneNumber);
        setIsSibling(props.student.isSibling);
    }, [props.student]);



    const handleAction = async () => {

        const timestamp = FirebaseTimestamp.now();
        const ref = studentsRef.doc();
        const id = ref.id;

        const studentTemp = {
            id: id,
            parentName: parentName,
            familyName: familyName,
            email: email,
            phoneNumber: phoneNumber,
            address: address,
            grade: grade,
            gender: gender,
            givenName: givenName,
            familyNameJapanese: familyNameJapanese,
            givenNameJapanese: givenNameJapanese,
            birthDay: birthDay,
            pickUp: pickUp,
            pickUpSchedule: pickUpSchedule,
            lineProfile: props.student.lineProfile,
            course: course,
            isSibling: isSibling,
            created_at: timestamp
        }

        if (studentTemp.grade === undefined) {
            alert("the grade is empty");
        } else {
            const newStusPrev = props.newStudents;
            const newStusNext = newStusPrev.filter((stu) => {
                if (stu.id !== studentId) {
                    return stu
                }
            })


            await newStudentsRef.doc(props.student.id).delete()
            props.setNewStudents(newStusNext)


            await studentsRef.doc(id).set(studentTemp)
                .then(() => {
                    alert("new student has been added to the main list!")
                }).catch((error) => {
                    throw new Error(error)
                });

            handleClose();
            // props.onDataChange()
        }


    }



    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>

                <div className={"text_field"}>
                    <TextInput
                        label={"姓"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={familyNameJapanese}
                        onChange={inputFamilyNameJapanese}
                    />
                    <TextInput
                        label={"名"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={givenNameJapanese}
                        onChange={inputGivenNameJapanese} />

                </div>

                <div className={"text_field"}>
                    <TextInput
                        label={"Family Name"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={parentName}
                        onChange={inputParentName}
                    />
                    <TextInput
                        label={"Given Name"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={givenName}
                        onChange={inputGivenName} />

                </div>

                <div className={"text_field"}>
                    <TextInput
                        label={"Email"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={email}
                        onChange={inputEmail}
                    />

                    <TextInput
                        label={"Phone Number"}
                        multiline={false}
                        required={true}
                        rows={1}
                        type={"text"}
                        value={phoneNumber}
                        onChange={inputPhoneNumber}
                    />
                </div>


                <TextInput
                    label={"Address"}
                    multiline={false}
                    required={true}
                    rows={1}
                    type={"text"}
                    value={address}
                    onChange={inputAddress}
                />

                <div className={"text_field"}>

                    <SelectBox
                        label={"Grade"}
                        required={true}
                        value={grade}
                        options={gradeOptions}
                        select={setGrade}
                    />

                    <SelectBox
                        label={"Gender"}
                        required={true}
                        value={gender}
                        options={genderOptions}
                        select={setGender}
                    />


                </div>



                <div className={"text_field"}>

                    <DatePickers
                        label={"Birthday"}
                        required={true}
                        value={birthDay}
                        select={setBirthday}
                    />

                </div>



                <MultipleSelect
                    label={"Courses"}
                    options={courses}
                    value={course}
                    select={setCourse}
                />

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        label="PickUp"
                        margin='dense'
                        multiline={1}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AirportShuttleIcon
                                        color="action"
                                        onClick={handleClickAction}
                                    />
                                </InputAdornment>
                            )
                        }}
                        required={true}
                        value={pickUp}
                        variant={props.variant}

                    />
                </form>



                <SwitchLabel checked={isSibling} label={"Sibling Discount?"} onChange={toggleIsSibling} />

                <DialogActions>

                    <Button
                        onClick={() => {
                            handleAction()
                        }}

                        color="primary" autoFocus>
                        approve
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleCloseSchedule}
            >
                <DialogTitle id="alert-dialog-title-seningSchedule">送迎予定</DialogTitle>
                <Divider />
                <BasicGrid
                    pickUp={pickUp}
                    setPickUp={setPickUp}
                    pickUpSchedule={pickUpSchedule}
                    setPickUpSchedule={setPickUpSchedule}
                />
            </Dialog>
        </div>
    );
}

export default ModalWindowNewStus;