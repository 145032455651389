import { FirebaseTimestamp, db} from '../../firebase/firebase';
import {fetchStudentsEachMonthAction} from './action'

const studentsEachMonthRef = db.collection("studentsEachMonth")

export const fetchStudentsEachMonth =()=>{
  return async(dispatch) =>{
      studentsEachMonthRef.orderBy('created_at', 'desc').get()
       .then(snapshots=>{
         const studentsEachMonthList = []
         snapshots.forEach(snapshot=>{
           const data = snapshot.data();
           studentsEachMonthList.push(data)
         })
         dispatch(fetchStudentsEachMonthAction(studentsEachMonthList))
       })
  }
}

export const saveStudentsEachMonth = (studentsEachMonth) =>{
  return async(dispatch) =>{
    const timestamp = FirebaseTimestamp.now()
    const ref = studentsEachMonthRef.doc();
    const id = ref.id;
    var dd = new Date();
    var yyyy = dd.getFullYear();
    var MM = dd.getMonth()+1;

    const addMonthId = studentsEachMonth.map((stu)=>{
        stu.monthId = id;
        return stu    
    })

    console.log(addMonthId);
     
    const data = {
      id:id,
      savedMonth: yyyy +"/"+MM,
      // isSaved:true,
      studentsEachMonth: addMonthId,
    }   
    data.created_at = timestamp

    return studentsEachMonthRef.doc(id).set(data, {merge:true}) 
    .then(() => {
    
}).catch((error)=>{
  throw new Error(error)
})
  }
};

export const changeStudentsEachMonth = (id,savedMonth,studentEachMonth) =>{
  return async(dispatch) =>{ 
    const data = {
      id : id,
      savedMonth:savedMonth,
      studentsEachMonth:studentEachMonth,
    }
    return studentsEachMonthRef.doc(id).set(data, {merge:true}) 
    .then(() => {
}).catch((error)=>{
  throw new Error(error)
})
  }
};

export const changeStudentsEachMonthNew = (student) =>{
  return async()=>{
 const studentsRef=studentsEachMonthRef.doc(student.monthId);
 const studentsEachMonthNow =  (await studentsRef.get()).data();
 
 const studentsEachMonthRenew = studentsEachMonthNow.studentsEachMonth.map((stu)=>{
    if(student.id === stu.id){
      console.log(stu);
       stu = student;
     return stu
    }else{
      return stu
    }
  })

  const data={
    id: studentsEachMonthNow.id,
    savedMonth:studentsEachMonthNow.savedMonth,
    studentsEachMonth:studentsEachMonthRenew
  }
    return studentsRef.set(data,{merge:true})
    .then(()=>{})
    .catch((error)=>{
      throw new Error(error)
    })
  }
};


  



