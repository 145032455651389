import {createSelector} from 'reselect';

const classesSelector =(state) => state.classes;

export const getClasses = createSelector(
  [classesSelector],
  state => state.list
)

export const getIsMain = createSelector(
  [classesSelector],
  state=> state.isMain
)

export const getIsTemp = createSelector(
  [classesSelector],
  state=> state.isTemp
)

export const getIsDiscount = createSelector(
  [classesSelector],
  state=> state.isDiscount
)
