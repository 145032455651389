const inititalState ={
  users:{
   isSignedIn:false,
   role:"",
   uid:"",
   username:""
  }
,
  classes:{
    list:[]
  }
,
  students:{
     list:[]
  },

  studentsEachMonth:{
       list:[]
  },
  
};

export default inititalState;
