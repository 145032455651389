import React, { useState, useCallback, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { db, FirebaseTimestamp } from '../../firebase/firebase';
import TextInput from '../../Tools/TextInput';
import { SwitchLabel } from "../../Tools/Index";




const ModalNewClass = (props) => {

    const classesRef = db.collection('classes');
    const [classname, setClassName] = useState("");
    const [cPrice, setClassPrice] = useState("");
    const [isMain, setIsMain] = useState(false);
    const [isTemp, setIsTemp] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);


    const handleClickSave = async () => {
        const timestamp = FirebaseTimestamp.now();
        const ref = classesRef.doc();
        const id = ref.id
        let tempLecs = props.lecs
        const order = tempLecs.length


        const data = {
            id: id,
            classname: classname,
            cPrice: parseInt(cPrice, 10),
            isMain: isMain,
            isTemp: isTemp,
            isDiscount: isDiscount,
            order: order,
            created_at: timestamp
        }

        tempLecs.push(data);
        props.setLecs(tempLecs);
        await classesRef.doc(id).set(data, { merge: true })
        handleClose();
    }

    const handleClose = () => {
        setClassName("");
        setClassPrice("");
        setIsMain(false);
        setIsTemp(false);
        setIsDiscount(false);

        props.setOpen(false)
    };

    const toggleIsMain = () => {
        setIsMain(!isMain)
    }

    const toggleIsTemp = () => {
        setIsTemp(!isTemp)
    }

    const toggleIsDiscount = () => {
        setIsDiscount(!isDiscount)
    }

    const inputClassName = useCallback((e) => {
        setClassName(e.target.value)
    }, [setClassName]);

    const inputClassPrice = useCallback((e) => {
        setClassPrice(e.target.value)
    }, [setClassPrice]);


    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
            >

                <DialogTitle>
                    Register New Class
                </DialogTitle>
                <DialogContent>

                    <DialogContentText >
                        Class ID: new class (no Id yet)
                    </DialogContentText>


                    <DialogContentText>
                        New Class Name
                    </DialogContentText>

                    <TextInput
                        required={false}
                        type={'text'}
                        value={classname}
                        onChange={inputClassName}
                    />

                    <DialogContentText >
                        New Class Price
                    </DialogContentText>

                    <TextInput
                        required={false}
                        type={'number'}
                        value={cPrice}
                        onChange={inputClassPrice}
                    />

                </DialogContent>


                <div className='class_form'>
                    <div className='class_form_buttons'>
                        <SwitchLabel
                            checked={isMain}
                            color={"primary"}
                            label={"Main couse?"}
                            onChange={toggleIsMain}
                        />

                        <SwitchLabel
                            checked={isTemp}
                            label={"Temporary?"}
                            onChange={toggleIsTemp}
                        />

                        <SwitchLabel
                            checked={isDiscount}
                            label={"Discount?"}
                            onChange={toggleIsDiscount}
                        />
                    </div>

                    <div>
                        <Button
                            onClick={handleClickSave}
                            color="primary">
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>

    )
}


export default ModalNewClass
