import React, { useState, useEffect } from 'react';
import { fetchClasses } from '../../reducks/classes/operations';
import { useDispatch, useSelector } from 'react-redux';
import "../../Styles/Styles.css";
import { getClasses } from "../../reducks/classes/selectors";
import StudentList from "./StudentList";
import { PButton } from '../../Tools/Index';
import SelectMonth from './SelectMonth';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import BulkResistration from "./BulkResistration";
import IRswitch from "./Switch";
import StudentsRecieptList from "./StudentListReciept";
import PublishRvFinal from "./PublishRvFinal";
import SendEmailToAll from "./SendEmailToAll";
import { db, FirebaseTimestamp } from '../../firebase/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import Pagination from '@material-ui/lab/Pagination';
import ModalWindowAddStu from "../StudentsEachMonth/ModalWindowAddStu"
import CsvOutput from "./CsvOutput";
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },

  grow: {
    flexGrow: 1,
  },

  card: {
    minWidth: 250,
    width: "400px",
    height: "300px",
    marginRight: 20,
    marginTop: 20,
    padding: 5,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    backdrop: {
      zIndex: 20000,
      // color: '#fff',
    },
  },
}));

const Students = () => {

  const dispatch = useDispatch()
  const design = useStyles();
  const [page, setPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [scaleFactor, setScaleFactor] = useState("")
  const [MonthList, setMonthList] = useState([]);
  const [baseStudents, setBaseStudents] = useState([]);
  const [studentsAllMonthHeader, setStudentsAllMonthHeader] = useState(null);
  const [studentsAllMonth, setStudentsAllMonth] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [allStu, setAllStu] = useState([]);
  const [studentsEachMonthId, setStudentsEachMonthId] = useState([]);
  const [maxPage, setMaxPage] = useState("");
  const [InvRcpt, SetInvRcpt] = useState(false)
  const [exStu, setExStu] = useState(null);
  const [count, setCount] = useState(0);
  const [transferFee, setTransferFee] = useState("");
  const [nOfStuSoroban, setNOfStuSoroban] = useState(0);
  const [discountSoroban, setDiscountSoroban] = useState(0);
  const [earningsSoroban, setEarningsSoroban] = useState(0);
  const [totalSoroban, setTotalSoroban] = useState(0);
  const [loading, setLoading] = useState(false);




  const studentsEachMonthRef = db.collection("studentsEachMonth")
  const studentsRef = db.collection("students")
  const transferRef = db.collection("transfer")


  const fetchTransferRef = async () => {
    transferRef.get()
      .then(snapshot => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          setTransferFee(data.transferFee);
          setScaleFactor(data.scaleFactor);
        })
      })
  }

  const fetchStudentsEachMonth = async () => {
    studentsEachMonthRef.orderBy('created_at', 'desc').limit(6).get()
      .then(snapshots => {
        const studentsEachMonthList = []
        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsEachMonthList.push(data)
        })
        const Temp = studentsEachMonthList.map((data) => data.savedMonth) // 各月
        console.log('header', studentsEachMonthList)
        setMonthList(Temp)
        setStudentsAllMonthHeader(studentsEachMonthList);
      })
  }


  const fetchStudentsEachMonthNewStructure = async (month) => {
    // const tempStudentAllMonth = studentsAllMonthHeader.map(async (month) => {
    const studentsRef = studentsEachMonthRef.doc(month.id).collection("students");
    const datas = studentsRef.orderBy('created_at', 'asc').get()
      .then(snapshots => {
        let studentsEachMonth = []

        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsEachMonth.push(data);
        })

        const eachMonthData = {
          id: month.id,
          savedMonth: month.savedMonth,
          created_at: month.created_at,
          students: studentsEachMonth
        }
        return eachMonthData
      })
    return datas
  }

  const fetchStudents = async () => {
    studentsRef.orderBy('created_at', 'asc').get()
      .then(snapshots => {
        const studentsList = []
        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsList.push(data)
        })
        setBaseStudents(studentsList)
      })
  }

  //各種変数を取り出し-----------------------
  const selector = useSelector((state) => state);
  const classes = getClasses(selector);
  //---------------------------------------------------

  useEffect(() => {
    fetchStudents();
    fetchTransferRef();
    fetchStudentsEachMonth();
    dispatch(fetchClasses());
  }, [])

  useEffect(() => {
    if (studentsAllMonthHeader) {
      let tempAllMonth = []
      studentsAllMonthHeader.forEach(month => {
        const monthData = fetchStudentsEachMonthNewStructure(month)
        tempAllMonth.push(monthData);
      })

      Promise.all(tempAllMonth).then(
        function onFulfilled(value) {
          setStudentsAllMonth(value)
        },
        function onRejected(reason) {
          console.log(reason)
        }
      )
    }
  }, [studentsAllMonthHeader])

  useEffect(() => {
    setSearchWord("");
  }, [selectedMonth])


  useEffect(() => {

    if (studentsAllMonth) {
      const List = studentsAllMonth.filter(data => data.savedMonth === selectedMonth);
      // 月のID取得-----------------------------------------------------
      const studentsEachMonthIdTemp = List.map(data => data.id);
      const studentsEachMonthIdTempTemp = studentsEachMonthIdTemp[0];
      setStudentsEachMonthId(studentsEachMonthIdTempTemp)
      //
      const studentsEachMonthTemp = List.map(data => data.students)
      let studentsEachMonth = studentsEachMonthTemp[0];

      let difference = []

      baseStudents.forEach((base) => {
        studentsEachMonth.forEach((stu) => {
          if (base.id === stu.id) {
            difference.push(base.id);
          }
        })
      });

      let result = baseStudents.filter(itemA =>
        // 配列Bに存在しない要素が返る
        difference.indexOf(itemA.id) === -1
      );

      setExStu(result);


      if (selectedMonth) {
        const tempMaxPage = Math.ceil(studentsEachMonth.length / 20);
        setMaxPage(tempMaxPage);
        const ArrayChunk = sliceByNumber(studentsEachMonth, 20)
        setFilteredStudents(ArrayChunk[page - 1]);
      }
      setAllStu(studentsEachMonth);
      setCount(count + 1);
    }

  }, [selectedMonth]);

  useEffect(() => {
    if (selectedMonth && searchWord === "") {
      const tempMaxPage = Math.ceil(allStu.length / 20);
      setMaxPage(tempMaxPage);
      const ArrayChunk = sliceByNumber(allStu, 20)
      setFilteredStudents(ArrayChunk[page - 1]);
    }
  }, [page, allStu])


  useEffect(() => {

    if (searchWord === "") {
      const tempMaxPage = Math.ceil(allStu.length / 20);
      setMaxPage(tempMaxPage);
      const ArrayChunk = sliceByNumber(allStu, 20)
      setFilteredStudents(ArrayChunk[page - 1]);
    }

    if (searchWord !== "") {

      let newStudents = allStu.filter((student) => {
        return (
          student.grade.indexOf(searchWord) !== -1 ||
          student.familyNameJapanese.indexOf(searchWord) !== -1 ||
          student.givenNameJapanese.indexOf(searchWord) !== -1 ||
          student.givenName.toLowerCase().indexOf(searchWord) !== -1 ||
          student.parentName.toLowerCase().indexOf(searchWord) !== -1 ||
          student.isPaid.indexOf(searchWord) !== -1 ||
          student.course
            .map((c) => c.classname.indexOf(searchWord) !== -1)
            .reduce((prev, current) => (prev || current))

        )
      })


      if (!isNaN(searchWord)) {
        newStudents = null;
        newStudents = allStu.filter((student) => {

          const total = student.course.reduce((prev, current) => {
            if (!isNaN(parseFloat(current.cPrice))) {
              return prev + parseFloat(current.cPrice)
            } else {
              return prev
            }
          }, 0)

          if ((total) === parseFloat(searchWord)) {
            return student
          }
        })
      }


      if (searchWord === "そろばん") {
        setNOfStuSoroban(newStudents.length);
        const tempDiscountSoroban = newStudents.reduce((p, c) => {
          if (c.isSibling) {
            const temptempDis = c.course.reduce((prev, cur) => {
              if (cur.classname.indexOf("そろばん") !== -1 && cur.classname.indexOf("年間諸経費") === -1
                && !isNaN(parseFloat(cur.cPrice))) {
                return prev + 0.2 * parseFloat(cur.cPrice);
              } else {
                return prev
              }
            }, 0)
            return p + temptempDis
          } else {
            return p
          }
        }, 0)

        setDiscountSoroban(tempDiscountSoroban);

        const tempTuitionSoroban = newStudents.reduce((p, c) => {
          const tempTempSoro = c.course.reduce((prev, cur) => {
            if (cur.classname.indexOf("そろばん") !== -1 && cur.classname.indexOf("年間") === -1) {
              if (!isNaN(parseFloat(cur.cPrice))) {
                return prev + parseFloat(cur.cPrice);
              } else {
                return prev
              }
            } else {
              return prev
            }
          }, 0)
          return p + tempTempSoro
        }, 0)

        setEarningsSoroban(tempTuitionSoroban);

        const tempSoroban = tempTuitionSoroban - tempDiscountSoroban

        setTotalSoroban(tempSoroban);
      }

      if (searchWord === "paid") {
        newStudents = null
        newStudents = studentsPaymentMonth(studentsAllMonth, selectedMonth);
      };

      if (searchWord === "krungsri") {
        newStudents = null;
        newStudents = studentsPaymentMonth(studentsAllMonth, selectedMonth)
          .filter((stu) => {
            return stu.bank.name === "Krungsri"
          });
      };

      if (searchWord === "bangkok") {
        newStudents = null;
        newStudents = studentsPaymentMonth(studentsAllMonth, selectedMonth).filter((stu) => {
          return stu.bank.name === "Bangkok Bank"
        });
      }
      setFilteredStudents(newStudents)
    }
  }, [searchWord]);


  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const studentsPaymentMonth = (allStudents, month) => {
    const date = new Date(month);
    const dateMonth = date.getMonth();
    const dateYear = date.getFullYear();

    let studentsPaid = [];

    allStudents.forEach((data) => {
      data.students.forEach((stu) => {
        if (stu.paymentDate !== null) {
          const pDate = stu.paymentDate.toDate();
          const pDateMonth = pDate.getMonth();
          const pDateYear = pDate.getFullYear();
          if (pDateYear === dateYear) {
            if (pDateMonth === dateMonth) {
              studentsPaid.push(stu)
            }
          }
        }
      })
    });

    // let tempTempStu = tempStu.map((data) => {
    //   return data.students
    // }).reduce((prev, current) => {
    //   return prev.concat(current)
    // }, [])

    studentsPaid.sort((prev, current) => {
      if (prev.paymentDate < current.paymentDate) {
        return -1;
      } else {
        return 1;
      }
    })
    return studentsPaid;
  }

  const Loading = () => {

    if (!studentsAllMonth) return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
          <p> now loading data... Please Wait.</p>
        </Backdrop>
      </div>

    )

    if (loading) return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
          <p> now making new data... Please Wait. </p>
        </Backdrop>
      </div>

    )

    if (!filteredStudents) {
      return (
        <Backdrop className={classes.backdrop} open={true}>
          {/* <CircularProgress color="inherit" /> */}
          <p> Please Select Distinated Month.</p>
        </Backdrop>
      )
    } else {
      return (
        <div>
          {(() => {
            if (!InvRcpt)
              if (searchWord !== "そろばん") {
                return (
                  <div>
                    <StudentList listId={studentsEachMonthId}
                      students={filteredStudents}
                      setStu={setFilteredStudents}
                      allStudents={allStu}
                      setAllStu={setAllStu}
                      selectedMonth={selectedMonth}
                      setCount={setCount}
                    />

                    {searchWord === "" && filteredStudents.length > 0 && (
                      <div className={design.root}>
                        <Pagination
                          count={maxPage}
                          page={page}
                          onChange={(e, page) => setPage(page)}
                          color="primary" />
                      </div>
                    )}
                  </div>
                )
              } else {
                return (
                  <div>
                    <Card className={"card"}>
                      <Typography color="textSecondary">
                        Soroban Income State
                      </Typography>
                      <Divider variant="middle" component="li" />
                      <Typography color="textSecondary">
                        N. of Students
                      </Typography>
                      <Typography className={"button_design"} gutterBottom>
                        {("000" + parseInt(nOfStuSoroban)).slice(-3)} 人
                      </Typography>
                      <Typography color="textSecondary">
                        Earnings
                      </Typography>
                      <Typography className={"button_design"} gutterBottom>
                        {earningsSoroban} Baht
                      </Typography>
                      <Typography color="textSecondary">
                        Discount
                      </Typography>
                      <Typography style={{ color: "red" }} className={"button_design"} gutterBottom>
                        {discountSoroban} Baht
                      </Typography>
                      <Divider variant="middle" />
                      <Typography style={{ fontSize: "20px" }} className={"button_design"}>
                        Total: {totalSoroban} Baht
                      </Typography>
                    </Card>

                    <StudentList listId={studentsEachMonthId}
                      students={filteredStudents}
                      setStu={setFilteredStudents}
                      allStudents={allStu}
                      setAllStu={setAllStu}
                      selectedMonth={selectedMonth}
                      setCount={setCount}
                    />

                    {searchWord === "" && filteredStudents.length > 0 && (
                      <div className={design.root}>
                        <Pagination
                          count={maxPage}
                          page={page}
                          onChange={(e, page) => setPage(page)}
                          color="primary" />
                      </div>
                    )}
                  </div>)
              }
            else
              return (
                <div>
                  <StudentsRecieptList
                    value={filteredStudents}
                    listId={studentsEachMonthId}
                    allStudents={allStu}
                    selectedMonth={selectedMonth}
                    searchWord={searchWord}
                  />
                  {searchWord === "" && filteredStudents.length > 0 && (
                    <div className={design.root}>
                      <Pagination
                        count={maxPage}
                        page={page}
                        onChange={(e, page) => setPage(page)}
                        color="primary" />
                    </div>
                  )}
                </div>
              )
          })()
          }
        </div>
      )
    }
  }

  // create new を押した時の処理”初めて”編集する際の初期処理 関数→Firebaseに保存 新規作成ボタンを押した時のみ反応するように
  const newStudentsMonthList = async (students, classes) => {
    setLoading(true);

    const timeStamp = FirebaseTimestamp.now();
    const ref = studentsEachMonthRef.doc()
    const monthId = ref.id;

    var dd = new Date();
    var yyyy = dd.getFullYear();
    var MM = dd.getMonth() + 1;
    const savedMonth = yyyy + "/" + MM;

    const data = {
      id: monthId,
      savedMonth: savedMonth,
      created_at: timeStamp
    }

    console.log(MonthList);
    const check = MonthList.some((month) => {
      return month === savedMonth
    })

    if (check) {
      alert("There is a month overlapping, can't creat new. If you wish to make new list,ask the administrator. ");
      setLoading(false);
    } else {

      await studentsEachMonthRef.doc(monthId).set(data, { merge: true })

      let tempStu = students;


      //  クラス情報と、生徒のクラス登録情報内容の一致の配列処理--------
      tempStu.forEach(data => {
        let preCourses = [];
        data.course.forEach(value => {
          const item = classes.filter((v) => value.id.includes(v.id));
          preCourses = [...preCourses, ...item];
        }
        )

        const courses = preCourses.filter((cours) => {
          return cours.cPrice !== 0;
        }
        )

        const tempCourses = courses.map((v) => {
          if (v.isTemp) {
            return v

          } else {
            return {
              cPrice: parseFloat(v.cPrice) * parseFloat(scaleFactor),
              classname: v.classname, created_at: v.created_at,
              id: v.id,
              isDiscount: v.isDiscount,
              isMain: v.isMain,
              isTemp: v.isTemp,
              order: v.order,
              updated_at: v.updated_at
            }
          }

        })

        data.course = [];
        data.course = tempCourses;
      })

      // -------------------------------------------------------------

      // 兄弟割引、併用割引、送迎費の計算+++++++++++++++++++++++++++++++++

      tempStu.forEach(data => {
        const Bank = {
          id: null,
          accountNumber: null,
          name: null,
          logo: null
        }

        const images = {
          id: null,
          path: null
        }
        data.bank = Bank;
        data.paymentDate = null;
        data.images = images;
        data.isPaid = "not yet";
        data.isSent = "undelivered";
        data.monthId = monthId;

        const findisSibling = data.isSibling
        if (findisSibling === true) {
          const siblingDiscountValue = data.course.reduce((prev, current) => {
            if (current.isMain === true || current.isDiscount === true) {
              return prev + -0.2 * parseInt(current.cPrice, 10);
            } else {
              return prev;
            }
          }, 0)

          const siblingDiscountValueData = {
            cPrice: siblingDiscountValue,
            classname: "兄弟割引",
            created_at: "",
            isDiscount: false,
            isMain: false,
            isTemp: true,
            id: "Sdiscount" + data.id
          }
          if (siblingDiscountValue !== 0) {
            data.course.push(siblingDiscountValueData);
          }


        } else {
          const findIsMain = data.course.findIndex(v => v.isMain === true)
          const discountValue = data.course.reduce((prev, current) => {
            if (current.isDiscount) {
              return prev + -0.1 * parseInt(current.cPrice, 10)
            } else {
              return prev
            }
          }, 0)

          if (findIsMain !== -1 && discountValue !== 0) {
            const discountValueData = {
              cPrice: discountValue,
              classname: "併用割引",
              created_at: "",
              isDiscount: false,
              isMain: false,
              isTemp: true,
              id: "discount" + data.id
            }
            data.course.push(discountValueData)
          }
        }
        if (data.pickUp !== "") {
          let transportationFee = data.pickUp
          transportationFee = transferFee * parseInt(data.pickUp, 10) * parseInt(scaleFactor)
          const transportationFeeData = {
            cPrice: transportationFee,
            classname: "送迎費",
            created_at: "",
            isDiscount: false,
            isMain: false,
            isTemp: true,
            id: "transportion" + String(data.id)
          }
          if (transportationFee !== 0) {
            data.course.push(transportationFeeData);
          }
        }


        for (let i = 0; i = 8 - data.course.length; i++) {
          const initialData = {
            cPrice: "",
            classname: "",
            created_at: "",
            isDiscount: false,
            isMain: false,
            isTemp: true,
            id: "courses" + String(i)
          }
          data.course.push(initialData);
        }
      }
      ); //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

      tempStu.forEach(async (data) => {
        const stuId = data.id
        await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(data);
      })

      setLoading(false);

    }



  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      setSearchWord(e.target.value);
    }
  }

  // const multipliyer=(allStu,studentEachMonthId,selectedMonth)=>{

  //   const tempStu = allStu.map((stu)=>{
  //          stu.monthId=studentEachMonthId;
  //         return stu;
  //  })

  //  setAllStu(tempStu);
  //  changeStudentsEachMonth(studentEachMonthId,selectedMonth,tempStu);
  // }

  // const changeStudentsEachMonth = async (id,savedMonth,studentEachMonth) =>{
  //   const studentsEachMonthRef = db.collection("studentsEachMonth")
  //    const data = {
  //     id : id,
  //     savedMonth:savedMonth,
  //     studentsEachMonth:studentEachMonth}

  //   try {
  //     await studentsEachMonthRef.doc(id).set(data, { merge: true });
  //   } catch (error) {
  //     throw new Error(error);
  //   }
  // };



  return (
    <>
      <div className={"icon_design"}>
        <PButton label={"Creat New"}
          variant={"outlined"}
          color={"secondary"}
          onClick={() => newStudentsMonthList(baseStudents, classes)} />

        {/* <PButton label={"multipliyer"}
         variant={"outlined"}
         color={"primary"} 
         onClick={() => multipliyer(allStu,studentsEachMonthId,selectedMonth)}
          />   */}

        <BulkResistration variant={"outlined"}
          selectedMonth={selectedMonth}
          students={allStu}
          studentsEachMonthId={studentsEachMonthId}
          filteredStudents={filteredStudents}
          setFilteredStudents={setFilteredStudents}
          setAllStu={setAllStu}
          setCount={setCount}
        />

        <ModalWindowAddStu students={exStu} selectedMonth={selectedMonth} studentsEachMonthId={studentsEachMonthId}
          allStu={allStu} setAllStu={setAllStu}
        />

        <SelectMonth onChange={setSelectedMonth}
          value={selectedMonth}
          lists={MonthList}
          disabled={!studentsAllMonth}
        />

        <div className={design.search}>
          <div className={design.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            id="searchBar"
            placeholder="Search…"
            classes={{
              root: design.inputRoot,
              input: design.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            type="search"
            onKeyDown={handleSearch}
          />
        </div>

        <div className={"buton_sendMailToAll"}>
          <SendEmailToAll students={filteredStudents}
            searchWord={searchWord}
            allStu={allStu}
            selectedMonth={selectedMonth}
            listId={studentsEachMonthId} />
        </div>

        <div className="button_publish">
          <CsvOutput studentsAllMonth={studentsAllMonth}
            selectedMonth={selectedMonth} />

          <PublishRvFinal
            studentsAllMonth={studentsAllMonth}
            allStu={allStu}
            listId={studentsEachMonthId}
            MonthList={MonthList}
            selectedMonth={selectedMonth} />
        </div>

        <IRswitch onChange={SetInvRcpt} checked={InvRcpt} />

        {/* <PButton label={"check"} variant={"outlined"} color={"secondary"} onClick={() =>check(allStu)} /> */}

      </div>
      <div key={"akiraSato" + count}>
        {Loading()}
      </div>
    </>
  );
}

export default Students;
