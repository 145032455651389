import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

}));

const SelectMonth = (props) => {

  const classes = useStyles();
  return (
    <div>


      <FormControl className={classes.formControl} disabled={props.disabled}>
        <InputLabel>{"Select Month"}</InputLabel>
        <Select
          variant={"standard"}
          value={props.value}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}

          className={classes.selectEmpty}
        >

          {props.lists.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
    </div>
  );
}

export default SelectMonth;

