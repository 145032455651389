import React, { Fragment} from "react";
import { DatePicker } from "@material-ui/pickers";




const PDatePicker=(props)=> {

  return (
    <Fragment>
      <DatePicker
       variant="inline"
        label="Payment Date"
        value={props.value}
        placeholder={"Payment Date"}
        onChange={props.onChange}
        animateYearScrolling
      />
    </Fragment>
  );
}

export default PDatePicker;