import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ModalWindowNewStus from './ModalWindowNewStus';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeclineNewStu from './DeclineNewStu';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});



const TableNewStus = (props) => {

  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [editStudent, setEditStudent] = useState(null);
  const [declineStu, setDeclineStu] = useState(null);

  const handleClickOpen = (data) => {
    setOpen(true);
    setEditStudent(data)
  };

  const handleDecline = (data) => {
    setDeclineOpen(true);
    setDeclineStu(data);
  }




  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Student</StyledTableCell>
              <StyledTableCell align="left">Class</StyledTableCell>
              <StyledTableCell align="left">PickUp</StyledTableCell>
              <StyledTableCell align="left">Address</StyledTableCell>
              <StyledTableCell align="left">Tel</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Edit&Approve</StyledTableCell>
              <StyledTableCell align="left">Decline</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {props.newStudents.map((data) => (
              <StyledTableRow key={data.id}>
                <StyledTableCell align="left">{data.familyNameJapanese + " " + data.givenNameJapanese}</StyledTableCell>
                <StyledTableCell align="left">{data.course.map((value) => value.classname).join(", ")}</StyledTableCell>
                <StyledTableCell align="left">{data.pickUp}</StyledTableCell>
                <StyledTableCell align="left">{data.address}</StyledTableCell>
                <StyledTableCell align="left">{data.phoneNumber}</StyledTableCell>
                <StyledTableCell align="left">{data.email}</StyledTableCell>
                <StyledTableCell align="left">
                  <CreateOutlinedIcon onClick={() => { handleClickOpen(data) }} />
                </StyledTableCell>
                <StyledTableCell align="left"><DeleteOutlinedIcon onClick={() => { handleDecline(data) }} /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {editStudent && (
        <ModalWindowNewStus
          open={open}
          onChange={setOpen}
          newStudents={props.newStudents}
          setNewStudents={props.setNewStudents}
          student={editStudent}

        />
      )}
      <DeclineNewStu
        open={declineOpen}
        onChange={setDeclineOpen}
        declineStu={declineStu}
        newStudents={props.newStudents}
        setNewStudents={props.setNewStudents}
      />
    </div>
  );
}

export default TableNewStus;