import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import SubjectIcon from '@material-ui/icons/Subject';
import { TextInput, SelectBox, DatePickers } from '../../Tools/Index';
import ClassSelector from '../../Tools/ClassSelector'
import Divider from '@material-ui/core/Divider';
import { SwitchLabel } from "../../Tools/Index";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import BasicGrid from '../NewStudents/BasicGrid';
import { FirebaseTimestamp, db } from '../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



const ModalWindowStudent = (props) => {

  const carSchedule = [
    { day: "月", pickUp: false, sendBack: false },
    { day: "火", pickUp: false, sendBack: false },
    { day: "水", pickUp: false, sendBack: false },
    { day: "木", pickUp: false, sendBack: false },
    { day: "金", pickUp: false, sendBack: false },
    { day: "土", pickUp: false, sendBack: false }
  ]

  const classes = useStyles();
  const studentsRef = db.collection('students');

  const [open, setOpen] = useState(false);
  const [openPickUpSchdule, setOpenPickUpSchedule] = useState(false);
  const [openCourses, setOpenCourses] = useState(false);
  const [parentName, setParentName] = useState("");
  const [gender, setGender] = useState("");
  const [grade, setGrade] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [familyNameJapanese, setFamilyNameJapanese] = useState("");
  const [givenNameJapanese, setGivenNameJapanese] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [birthDay, setBirthday] = useState("");
  const [address, setAddress] = useState("");
  const [course, setCourse] = useState([]);
  const [pickUp, setPickUp] = useState("");
  const [pickUpSchedule, setPickUpSchedule] = useState(carSchedule);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSibling, setIsSibling] = useState(false);



  const inputParentName = useCallback((e) => {
    setParentName(e.target.value)
    setFamilyName(e.target.value)
  }, [setParentName]);

  const inputGivenName = useCallback((e) => {
    setGivenName(e.target.value)
  }, [setGivenName]);


  const inputFamilyNameJapanese = useCallback((e) => {
    setFamilyNameJapanese(e.target.value)
  }, [setFamilyNameJapanese]);

  const inputGivenNameJapanese = useCallback((e) => {
    setGivenNameJapanese(e.target.value)
  }, [setGivenNameJapanese]);

  const inputEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [setEmail]);

  const inputPhoneNumber = useCallback((e) => {
    setPhoneNumber(e.target.value)
  }, [setPhoneNumber]);

  const inputAddress = useCallback((e) => {
    setAddress(e.target.value)
  }, [setAddress]);


  const toggleIsSibling = () => {
    setIsSibling(!isSibling)
  }

  const handleClickOpenSelectCouses = () => {
    setOpenCourses(true);
  }


  const genderOptions = [
    { id: "Mr.", name: "Mr." },
    { id: "Ms.", name: "Ms." }
  ]

  const gradeOptions = [
    { id: "年少", name: "年少" }, { id: "年中", name: "年中" }, { id: "年長", name: "年長" },
    { id: "小1", name: "小1" }, { id: "小2", name: "小2" }, { id: "小3", name: "小3" }, { id: "小4", name: "小4" }, { id: "小5", name: "小5" }, { id: "小6", name: "小6" },
    { id: "中1", name: "中1" }, { id: "中2", name: "中2" }, { id: "中3", name: "中3" },
    { id: "高1", name: "高1" }, { id: "高2", name: "高2" }, { id: "高3", name: "高3" }, { id: "クレ", name: "クレ" }
  ]


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPickUpSchdule = () => {
    setOpenPickUpSchedule(true);
  }

  const handleCloseSchedule = () => {
    setOpenPickUpSchedule(false);
  }

  const handleOpenCourses = () => {
    setOpenCourses(!openCourses)
  }

  const handleDelete = (cour) => {
    const tempCourse = course.filter((temp) => {
      if (temp.id !== cour.id)
        return temp
    })

    setCourse(tempCourse);
  }

  const handleClickRegister = async () => {
    const ref = studentsRef.doc();
    const id = ref.id;
    const timestamp = FirebaseTimestamp.now()

    let tempAllStu = props.allStu;

    const newStudent = {
      address: address,
      birthDay: birthDay,
      course: course,
      created_at: timestamp,
      email: email,
      familyName: familyName,
      familyNameJapanese: familyNameJapanese,
      gender: gender,
      givenName: givenName,
      givenNameJapanese: givenNameJapanese,
      grade: grade,
      id: id,
      isSibling: isSibling,
      parentName: parentName,
      phoneNumber: phoneNumber,
      pickUp: pickUp,
      pickUpSchedule: pickUpSchedule,
      schoolName: schoolName
    }

    tempAllStu.push(newStudent);

    props.setStudents(tempAllStu)
    props.setCount(props.count + 1)

    await studentsRef.doc(id).set(newStudent, { merge: true })
    Clear()
  }





  const Clear = () => {
    setParentName("")
    setGender("")
    setGrade("")
    setPhoneNumber("")
    setPickUp("")
    setSchoolName("")
    setFamilyNameJapanese("")
    setGivenName("")
    setGivenNameJapanese("")
    setBirthday("")
    setCourse([])
    setEmail("")
    setFamilyName("")
    setAddress("")
    setFamilyName("")
    setPickUpSchedule(carSchedule)
    setIsSibling(false)
    handleClose()
  }


  return (
    <div>
      <Button
        color={"primary"}
        onClick={handleClickOpen}
        variant="outlined"
      >
        Add New Student
      </Button>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Student</DialogTitle>

        <div className={"text_field"}>
          <TextInput
            label={"姓"}
            multiline={false}
            required={true}
            type={"text"}
            value={familyNameJapanese}
            onChange={inputFamilyNameJapanese}
          />
          <TextInput
            label={"名"}
            multiline={false}
            required={true}
            type={"text"}
            value={givenNameJapanese}
            onChange={inputGivenNameJapanese} />

        </div>

        <div className={"text_field"}>
          <TextInput
            label={"Family Name"}
            multiline={false}
            required={true}
            type={"text"}
            value={parentName}
            onChange={inputParentName}
          />
          <TextInput
            label={"Given Name"}
            multiline={false}
            required={true}
            type={"text"}
            value={givenName}
            onChange={inputGivenName} />

        </div>

        <div className={"text_field"}>
          <TextInput
            label={"Email"}
            multiline={false}
            required={true}
            type={"text"}
            value={email}
            onChange={inputEmail}
          />

          <TextInput
            label={"Phone Number"}
            multiline={false}
            required={true}
            type={"text"}
            value={phoneNumber}
            onChange={inputPhoneNumber}
          />
        </div>

        <TextField
          label={"Address"}
          multiline={false}
          required={true}
          style={{ width: 400, margin: 8 }}
          type={"text"}
          value={address}
          onChange={inputAddress}
        />



        <div className={"text_field"}>

          <SelectBox
            label={"Grade"}
            required={true}
            value={grade}
            options={gradeOptions}
            select={setGrade}
          />

          <SelectBox
            label={"Gender"}
            required={true}
            value={gender}
            options={genderOptions}
            select={setGender}
          />


        </div>

        <TextField
          style={{ margin: 8 }}
          label={"Courses"}
          required={true}
          multiline
          row={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SubjectIcon
                  color="action"
                  onClick={handleClickOpenSelectCouses}
                />
              </InputAdornment>),
            startAdornment: (
              <InputAdornment position="start">
                {course.map((cour, index) => {
                  return (
                    <Chip
                      key={"chip" + index}
                      label={cour.classname}
                      color="primary"
                      variant="outlined"
                      size='small'
                      onDelete={(() => { handleDelete(cour) })}
                    />
                  )

                })
                }
              </InputAdornment>

            )
          }}

        />



        <div className={"text_field"}>

          <DatePickers
            label={"Birthday"}
            required={true}
            value={birthDay}
            select={setBirthday}
          />

        </div>



        {/* <MultipleSelect
          label={"Courses"}
          options={courses}
          value={course}
          select={setCourse}
        /> */}

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="PickUp"
            margin='dense'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AirportShuttleIcon
                    color="action"
                    onClick={handleOpenPickUpSchdule}
                  />
                </InputAdornment>
              )
            }}
            required={true}
            value={pickUp}
            variant={props.variant}

          />
        </form>



        <SwitchLabel checked={isSibling} label={"Sibling Discount?"} onChange={toggleIsSibling} />

        <DialogActions>

          <Button
            color="primary"
            autoFocus
            onClick={handleClickRegister}
          >
            register
          </Button>
        </DialogActions>
      </Dialog>

      {/*  Open couses selector */}
      <Dialog
        open={openCourses}
        maxWidth={'sm'}
        onClose={handleOpenCourses}
      >

        <DialogTitle id="alert-dialog-title-seningSchedule">受講コース選択</DialogTitle>
        <Divider />
        <div>

          <ClassSelector
            lecs={props.lecs}
            course={course}
            setCourse={setCourse}
          />

        </div>

      </Dialog>

      {/* Open Bus Scedule */}
      <Dialog
        open={openPickUpSchdule}
        onClose={handleCloseSchedule}
      >
        <DialogTitle id="alert-dialog-title-seningSchedule">送迎予定</DialogTitle>
        <Divider />
        <BasicGrid
          pickUp={pickUp}
          setPickUp={setPickUp}
          pickUpSchedule={pickUpSchedule}
          setPickUpSchedule={setPickUpSchedule}
        />
      </Dialog>
    </div>
  );
}

export default ModalWindowStudent;