import React, { Fragment} from "react";
import { TimePicker } from "@material-ui/pickers";

function SecondsTimePicker(props) {

  return (
    <Fragment>
      <TimePicker
        variant='inline'
        ampm={false}
        openTo="hours"
        views={["hours", "minutes", "seconds"]}
        format="HH:mm:ss"
        label="Payment Time"
        value={props.value}
        onChange={props.onChange}
      />
    </Fragment>
  );
}

export default SecondsTimePicker;