import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses } from "../../reducks/classes/selectors";
import { fetchStudents } from '../../reducks/Students/operations';
import { getStudents } from "../../reducks/Students/selectors";
import { fetchClasses } from '../../reducks/classes/operations';
import Meters from './Meters';
import MetersSales from './MetersSales';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import BarGraph from "./EachClassInfo";
import PiChart from "./PiChart";
import MessageBox from "./MessageBox";
import { db, FieldValue } from '../../firebase/firebase';
import { PButton } from '../../Tools/Index';




const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 20000,
    color: '#fff',
  },
}));


const Home = () => {
  const studentsEachMonthRef = db.collection("studentsEachMonth")
  const dispatch = useDispatch();
  const classes = useStyles();

  const selector = useSelector(state => state);
  const students = getStudents(selector);
  const courses = getClasses(selector);


  const [studentsAllMonthHeader, setStudentsAllMonthHeader] = useState(null);
  const [studentsAllMonth, setStudentsAllMonth] = useState(null);




  useEffect(() => {
    const func = () => {
      fetchStudentsEachMonth()
      dispatch(fetchStudents())
      dispatch(fetchClasses())
    }
    func()

  }, []);

  useEffect(() => {
    if (studentsAllMonthHeader) {
      let tempAllMonth = []
      studentsAllMonthHeader.forEach(month => {
        const monthData = fetchStudentsEachMonthNewStructure(month)
        tempAllMonth.push(monthData);
      })

      Promise.all(tempAllMonth).then(
        function onFulfilled(value) {
          console.log(value);
          setStudentsAllMonth(value)
        },
        function onRejected(reason) {
          console.log(reason)
        }
      )
    }
  }, [studentsAllMonthHeader])

  const fetchStudentsEachMonth = async () => {
    studentsEachMonthRef.orderBy('created_at', 'desc').get()
      .then(snapshots => {
        const studentsEachMonthList = []
        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsEachMonthList.push(data)
        })

        setStudentsAllMonthHeader(studentsEachMonthList);
      })

  }

  const fetchStudentsEachMonthNewStructure = async (month) => {
    // const tempStudentAllMonth = studentsAllMonthHeader.map(async (month) => {
    const studentsRef = studentsEachMonthRef.doc(month.id).collection("students");
    const datas = studentsRef.orderBy('created_at', 'desc').get()
      .then(snapshots => {
        let studentsEachMonth = []

        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsEachMonth.push(data);
        })

        const eachMonthData = {
          id: month.id,
          savedMonth: month.savedMonth,
          created_at: month.created_at,
          students: studentsEachMonth
        }
        return eachMonthData
      })
    return datas
  }

  // const uploadNewDateStructure = async () => {
  //   studentsAllMonth.forEach((stusMonth) => {
  //     const students = stusMonth.studentsEachMonth;
  //     const monthId = stusMonth.id;

  //     students.forEach((stu) => {
  //       studentsEachMonthRef.doc(monthId).collection("students").doc(stu.id).set(stu, { merge: true })
  //         .then(() => {
  //         }).catch((error) => {
  //           console.log(error);
  //           throw new Error(error)

  //         })
  //     })
  //   })
  // }

  // const deleteStudentsEachMonth = async () => {
  //   studentsAllMonthHeader.forEach(async (month) => {

  //     const testId = month.id

  //     const monthRef = studentsEachMonthRef.doc(testId)

  //     const res = await monthRef.update({
  //       studentsEachMonth: FieldValue.delete()
  //     });
  //   })
  // }



  if (!studentsAllMonth) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
        <p> Loading File...</p>
      </Backdrop>
    )
  } else {
    const nOfStu = studentsAllMonth.map((month) => {

      return { "month": month.savedMonth, "NofStu": month.students.length }
    }).reverse();
    const nOfStuLatest = nOfStu[nOfStu.length - 1].NofStu
    const latestMonth = nOfStu[nOfStu.length - 1].month

    const calculateYoYNumber = () => {
      if (nOfStu.length - 13 < 0) {
        console.log(nOfStu[nOfStu.length - 2].month);
        return nOfStu[nOfStu.length - 2].NofStu
      } else {
        console.log(nOfStu[nOfStu.length - 13].month);
        return nOfStu[nOfStu.length - 13].NofStu

      }
    }

    const nOfStuSecondL = calculateYoYNumber();

    const Difference = nOfStuLatest - nOfStuSecondL;


    const Sales = studentsAllMonth.map((month) => {
      const salesEach = month.students.reduce((p, c) => {
        if (c.grade !== "クレ") {
          const stuSales = c.course.reduce((prev, current) => {
            if (!isNaN(parseFloat(current.cPrice))) {
              return prev + parseFloat(current.cPrice)
            }
            else {
              return prev
            }
          }, 0)
          return p + parseFloat(stuSales)
        } else {
          // if(!isNaN(parseFloat(c.course[1].cPrice)))
          return p
        }
      }, 0)

      return { "month": month.savedMonth, 'sales': salesEach }
    }).reverse();


    const SalesLatest = Sales[Sales.length - 1].sales

    const calculateYoYSales = () => {
      if (Sales.length - 13 < 0) {
        console.log(Sales[Sales.length - 2].month);
        return Sales[Sales.length - 2].sales
      } else {
        console.log(Sales[Sales.length - 13].month);
        return Sales[Sales.length - 13].sales
      }
    }
    const SalesSecondL = calculateYoYSales();

    const DifSales = SalesLatest - SalesSecondL

    const tempTemp = studentsAllMonth;
    const tempLastMonth = tempTemp[0].students
    const tempSecondLastMonth = tempTemp[1].students
    const monthSecondLast = tempTemp[1].savedMonth

    const notPaid = tempSecondLastMonth.filter((stu) =>
      stu.isPaid === "not yet"
    )

    const D = new Date();
    const month = D.getMonth() + 1;
    const day = D.getDate();

    const birthDay = students.filter((stu) => {
      const Birth = stu.birthDay.split("-")
      return parseInt(Birth[1]) === month && parseInt(Birth[2]) === day
    })

    const gradeOptions = [
      { id: "年少", name: "年少" }, { id: "年中", name: "年中" }, { id: "年長", name: "年長" },
      { id: "小1", name: "小1" }, { id: "小2", name: "小2" }, { id: "小3", name: "小3" }, { id: "小4", name: "小4" }, { id: 5, name: "小5" }, { id: 6, name: "小6" },
      { id: "中1", name: "中1" }, { id: "中2", name: "中2" }, { id: "中3", name: "中3" },
      { id: "高1", name: "高1" }, { id: "高2", name: "高2" }, { id: "高3", name: "高3" }, { id: "クレ", name: "クレ" }
    ]


    const parcentOfGrade = gradeOptions.map((grade) => {
      let number = 0
      tempLastMonth.forEach((stu) => {
        if (grade.name === stu.grade) {
          number = number + 1
        }
      })
      //  let parcent = number / nOfStuLatest * 100
      return { 'name': grade.name, 'value': number }
    })

    const eachCourses = courses.map((c) => {
      let Num = 0
      let Sales = 0
      tempLastMonth.forEach((stu) => {
        stu.course.forEach((cou) => {
          if (c.id === cou.id) {
            Num = Num + parseInt(1)
            Sales = Sales + parseInt(c.cPrice)
          }
        })
      })
      return { 'name': c.classname, "NofP": Num, 'Sales': Sales }
    })

    const travelFee = tempLastMonth.reduce((prev, cur) => {
      let Sales = 0;
      let Num = 0;
      const courses = cur.course;
      courses.forEach((course) => {
        if (course.classname === "送迎費" && course.cPrice !== "") {
          Sales = Sales + parseFloat(course.cPrice)
          Num = Num + 1;
        }
      })

      if (isNaN(prev.Sales)) {
        console.log(Sales, prev)
      }

      return { 'name': "送迎費", "NofP": prev.NofP + Num, "Sales": prev.Sales + Sales }

    }, { 'name': "送迎費", "NofP": 0, "Sales": 0 })


    const coursesAndTravelFee = [...eachCourses, travelFee];

    const rvr = tempLastMonth.filter((v) => v.isPaid === "paid")
    const recoveryAmount = rvr.reduce((prev, cur) => {
      let sales = 0
      // if(cur.grade !=="クレ"){
      cur.course.forEach((cour) => {
        if (!isNaN(parseInt(cour.cPrice))) {
          sales = sales + parseInt(cour.cPrice)
        }
      })
      return prev + sales
      //  }
    }, 0)

    const rv = (recoveryAmount / SalesLatest * 100).toFixed(0)

    const recoveryDatas = [{ "name": "Paid", 'value': parseInt(rv) },
    { "name": "not Paid", 'value': 100 - (rv) }]

    return (

      <main >
        <h1>DashBoard: Analytical Data and Infos</h1>

        {/* <PButton label={"change the data structure"}
          variant={"outlined"}
          color={"secondary"}
          onClick={() => uploadNewDateStructure()}
        />

        <PButton label={"delete StudentsEachMonth"}
          variant={"outlined"}
          color={"secondary"}
          onClick={() => deleteStudentsEachMonth()}
        /> */}




        <div className="text_field">
          <div>
            <Meters values={nOfStu} title={"Number of students"} content={"in Total"} month={latestMonth} latest={nOfStuLatest} dif={Difference} />

            <MetersSales values={Sales} title={"Sales"} month={latestMonth} latest={SalesLatest} dif={DifSales} />
          </div>
          <div>
            <BarGraph data={coursesAndTravelFee} title={latestMonth} />
            <div className="text_field">
              <PiChart data={parcentOfGrade} label={"Number of Each Grades [persons]"} title={"Number of people in Each grade"} />
              <PiChart data={recoveryDatas} label={rv + "% has paid"} title={"Recovery rate(" + recoveryAmount + "/" + SalesLatest + ")"} />
              <MessageBox notPaid={notPaid} monthSecondLast={monthSecondLast} birthDay={birthDay} />
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Home;