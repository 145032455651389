import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectBox=(props)=> {
  
  const classes = useStyles();

  return (
    <div>
      
     
      <FormControl required className={classes.formControl}>
       <InputLabel>{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.value}
          onChange={(event)=>{
                props.select(event.target.value)
          } }
          required={props.required}
          className={classes.selectEmpty}
        >

          {props.options.map ((option)=> (
                 <MenuItem key={option.id} value={option.id}>
                   {option.name}
                 </MenuItem>
          ) )} 
      
        </Select>
        
      </FormControl>
    </div>
  );
}

export default SelectBox;