import React from 'react';
import Image from 'react-image-resizer';

const style = {
  image: {
    border: '1px solid #ccc',
    background: '#fefefe',
    alignContent:'center'
  },
};


const ImagePreview=(props)=>{


  return(
    <div>
      <Image  alt={"upload"} src={props.path}
       height={ 600 }
       style={style.image}
      />
    </div>
  )
}

export default ImagePreview;
