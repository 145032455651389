import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextInput from '../../Tools/TextInput';
import { SwitchLabel } from "../../Tools/Index";
import { db } from '../../firebase/firebase';
import Card from '@material-ui/core/Card';
import '../../Styles/Styles.css';





const BulkResistration = (props) => {

  const studentsEachMonthRef = db.collection("studentsEachMonth")
  const randomId = Math.random().toString(32).substring(2);
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    const randomId = Math.random().toString(32).substring(2);
    setCourseId(randomId);
  };



  const [classname, setClassName] = useState("");
  const [cPrice, setClassPrice] = useState("");
  const [isMain, setIsMain] = useState(false);
  const [isTemp, setIsTemp] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [courseId, setCourseId] = useState(randomId);
  const filteredStudents = props.filteredStudents;

  // const [allStudents, setAllStudents] = useState([]);
  // const [filteredStudents, setFilteredStudents]  = useState([]);
  // const [selectedMonth, setSelectedMonth] = useState("");
  // const [studentsEachMonthId, setStudentsEachMonthId]= useState("");
  const [check, setCheck] = useState([]);

  // useEffect(()=>{
  //   setAllStudents(props.students);
  //   setFilteredStudents(props.filteredStudents);
  //   setSelectedMonth(props.selectedMonth);
  //   setStudentsEachMonthId(props.studentsEachMonthId);
  //  },[props.students,props.filteredStudents,props.selectedMonth,props.studentsEachMonthId])

  useEffect(() => {
    const int = intializeValue();
    setCheck(int);
  }, [open])


  const intializeValue = () => {
    const initCheck = filteredStudents.map(() => {
      return false
    })
    return initCheck;
  }


  const toggleIsMain = () => {
    setIsMain(!isMain)
  }

  const toggleIsTemp = () => {
    setIsTemp(!isTemp)
  }

  const toggleIsDiscount = () => {
    setIsDiscount(!isDiscount)
  }


  const inputClassName = useCallback((e) => {
    setClassName(e.target.value)
  }, [setClassName]);

  const inputClassPrice = useCallback((e) => {
    setClassPrice(e.target.value)
  }, [setClassPrice]);

  const addStudent = ((index) => {
    handleToggle(index);
  })

  const handleToggle = ((index) => {
    setCheck(
      check.map((value, i) => {
        if (i === index) {
          return (!value);
        } else {
          return (value)
        }
      })
    )
  })

  const handleToggleAll = (() => {
    setCheck(
      check.map((value) => {
        return !value
      }
      )
    )
  })

  const handleToggleAllReset = () => {
    setCheck(
      check.map(() => {
        return false
      })
    )
  }

  const handleClear = () => {
    setClassName("");
    setClassPrice("");
    setIsDiscount(false);
    setIsMain(false);
    setIsTemp(false);
  }

  const chooseStudents = (data, index) => {
    if (check[index]) {
      return (

        <Card style={{ color: "white", backgroundColor: "#4169E1" }} className={"insert_card"}
          onClick={() => { addStudent(index) }}
          key={data.id + index}
        >
          {data.familyNameJapanese + " " + data.givenNameJapanese}
        </Card>

      )

    } else {
      return (

        <Card className={"insert_card"}
          onClick={() => { addStudent(index) }}
          key={data.id + index}
        >
          {data.familyNameJapanese + " " + data.givenNameJapanese}
        </Card>

      )
    }
  }




  const handleDelete = (students, check) => {

    let chosenStus = students.filter((data, index) => {
      if (check[index]) {
        return data
      }
    })



    chosenStus.forEach((data) => {

      let courses = [];
      for (let i = 0; i < 8; i++) {
        const initialData = {
          cPrice: "",
          classname: "",
          created_at: "",
          isDiscount: false,
          isMain: false,
          isTemp: false,
          id: "courses" + String(i)
        }
        courses.push(initialData);
      }
      data.course = courses
    })

    console.log(chosenStus);

    chosenStus.forEach(async (data) => {
      const monthId = data.monthId
      const stuId = data.id
      await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(data, { merge: true });
    })



    const newStus = students.map((student) => {
      chosenStus.forEach((data) => {

        if (student.id === data.id) {
          student.course = data.course
        }
      })
      return student
    })

    //  dispatch(changeStudentsEachMonth(monthId,selectedMonth,students));

    props.setFilteredStudents(newStus);
    handleClose();
    handleClear();
    props.setCount(prev => prev + 1);
  }

  const handleAdd = async (students, classname, cPrice, isMain, isTemp, isDiscount, courseId, check) => {

    // const stusNow = await callStusNow(monthId);

    let chosenStus = students.filter((data, index) => {
      if (check[index]) {
        return data
      }
    });

    chosenStus.forEach((data) => {

      const newCourse = {
        cPrice: cPrice,
        classname: classname,
        created_at: "",
        isDiscount: isDiscount,
        isMain: isMain,
        isTemp: isTemp,
        id: courseId
      }


      let courses = data.course.filter((v) => v.classname !== "")

      courses.push(newCourse)

      for (let i = 0; i = 8 - courses.length; i++) {
        const initialData = {
          cPrice: "",
          classname: "",
          created_at: "",
          isDiscount: false,
          isMain: false,
          isTemp: true,
          id: "courses" + String(i)
        }
        courses.push(initialData);
      }
      data.course = courses;
    })


    chosenStus.forEach(async (stu) => {
      const monthId = stu.monthId;
      const stuId = stu.id
      await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(stu, { merge: true });
    })


    const newStus = students.map((student) => {
      chosenStus.forEach((data) => {

        if (student.id === data.id) {
          student.course = data.course
        }
      })
      return student
    })


    props.setFilteredStudents(newStus);
    props.setCount(prev => prev + 1);
    handleClose();
    handleClear();

  }

  if (open === true) {
    return (
      <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Bulk Resistration
        </Button>


        <Dialog
          open={open}
          onClose={handleClose}
        >

          <DialogTitle id="alert-dialog-title">{"Excute bulk resistration"}</DialogTitle>

          <DialogContentText id='alert-dialog-discriotion'>
            Class ID: :{courseId}
          </DialogContentText>


          <DialogContentText id='alert-dialog-discriotion'>
            Class Name
          </DialogContentText>

          <DialogContent>

            <TextInput
              // rows={1}
              required={false}
              type={'text'}
              value={classname}
              onChange={inputClassName}
            />


            <DialogContentText id="alert-dialog-description">
              Price
            </DialogContentText>

            <TextInput
              // rows={1} 
              required={false}
              type={'number'}
              value={cPrice}
              onChange={inputClassPrice}
            />


          </DialogContent>

          <div className={'tyle_arrangement'}>

            {filteredStudents.map((data, index) => {
              return (
                chooseStudents(data, index)
              )
            })}

            <Card
              className={"insert_card"}
              onClick={() => { handleToggleAll() }}
              style={{ backgroundColor: "#90EE90" }}>
              Select All
            </Card>

            <Card
              className={"insert_card"}
              onClick={() => { handleToggleAllReset() }}
              style={{ backgroundColor: "tomato", color: "white" }}>
              Reset
            </Card>
          </div>

          <DialogActions>


            <SwitchLabel checked={isMain} color={"primary"} label={"Main couse?"} onChange={toggleIsMain} />
            <SwitchLabel checked={isTemp} label={"Temporary?"} onChange={toggleIsTemp} />
            <SwitchLabel checked={isDiscount} label={"Discount?"} onChange={toggleIsDiscount} />

            <Button color="primary" onClick={() => { handleAdd(filteredStudents, classname, cPrice, isMain, isTemp, isDiscount, courseId, check) }}>
              Add
            </Button>

            <Button onClick={() => { handleDelete(filteredStudents, check) }} color="secondary">
              Delete
            </Button>


          </DialogActions>
        </Dialog>
      </div>
    );

  } else {
    return (<Button variant="outlined" onClick={handleClickOpen}>
      Bulk Resistration
    </Button>)
  };

}

export default BulkResistration;