import React,{useState,useCallback} from 'react'; 
import TextInput from '../Tools/TextInput';
import PButton from "../Tools/Button";
import "../Styles/Styles.css";
import { useDispatch} from 'react-redux';
import {signIn} from "../reducks/users/operations";


const SignIn =() =>{
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const inputEmail = useCallback((e)=>{
       setEmail(e.target.value)
  },[])

  const inputPassword = useCallback((e)=>{
    setPassword(e.target.value)
},[])

const dispatch = useDispatch();
// const selector = useSelector(state=>state);

  return(
    <div className={"sign-in"} >
    <div className={"center"}>
    <h2> SANARU THAILAND Organization System </h2>
    <div className={"position_margin"}/>
    </div>
    <div className={"position_center"}>
    <TextInput
       label ={"Email"} multiline= {false} required={true} row={1} value={email} type={"email"} onChange={inputEmail} />
    <TextInput
         label ={"Password"} multiline= {false} required={true} row={1} value={password} type={"password"} onChange={inputPassword} />
    <div className={"position_margin"}/>
    <PButton label={"Sign In"} onClick={()=> dispatch(signIn(email,password))}/>
    </div>
    </div>
  )
}

export default SignIn;
