import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextInput from '../../Tools/TextInput';
import { SwitchLabel } from "../../Tools/Index";
import { db, FirebaseTimestamp } from "../../firebase/firebase";
import { DialogTitle } from '@material-ui/core';


const ModalTransfer = (props) => {

    const transferRef = db.collection('transfer');
    const [transferFee, setTransferFee] = useState(props.transferFee);


    useEffect(() => {
        setTransferFee(props.transferFee)
    }, [props.transferFee])

    const handleClose = () => {
        setTransferFee(props.transferFee);
        props.setOpen(false)
    }

    const handleClickSave = async () => {

        const timestamp = FirebaseTimestamp.now()
        const id = "transferFee";

        const data = {
            id: id,
            transfer: "送迎費",
            transferFee: transferFee,
            updated_at: timestamp
        }

        await transferRef.doc(id).set(data, { merge: true })
        props.setTransferFee(transferFee);
        handleClose();
    }

    const inputTransferFee = useCallback((e) => {
        setTransferFee(e.target.value);
    }, [setTransferFee])




    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogContent>

                <DialogTitle>
                    Transfer Fee
                </DialogTitle>

                <DialogContentText >
                    基本送迎費（一回あたり）
                </DialogContentText>




                <TextInput
                    required={false}
                    type={'number'}
                    value={transferFee}
                    onChange={inputTransferFee}
                />

            </DialogContent>


            <div className="right" >

                <Button
                    onClick={handleClickSave}
                    color="primary">
                    Save
                </Button>


            </div>

        </Dialog>
    )
}

export default ModalTransfer;

