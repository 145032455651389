import { IconButton, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {storage} from '../../firebase/firebase';


const displayButton ={
   display: "none"
}



const useStyle= makeStyles({
    icon:{
      height:48,
      width:48
    }
})

const ImageArea=(props)=>{
  const classes = useStyle();

  const FolderName = props.selectedMonth.split('/')
                                        .reduce((prev,current)=>{
                                          return prev+'_'+current;
                                        })

  const uploadImage=useCallback((event)=>{
    const file = event.target.files;
    let blob = new Blob(file, {type:"image/jpeg"});

    const S ="abcdefghijklmnopqrstuvwxyz0123456789";
    const N = 16;
    const randomFileName= Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n)=>S[n%S.length]).join("");

    const fileName =props.student.familyName+props.student.givenName+"_"+randomFileName;
     
 
  
    const uploadRef = storage.ref(FolderName).child(fileName);
    const uploadTask= uploadRef.put(blob);

    uploadTask.then(()=>{
       uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
         const newImage ={id:fileName,path:downloadURL}
         props.setImages(newImage);
       }
       )
    })
  },[props.setImages])

  return(
    <div>
  
      <div>
        
        <IconButton className={classes.icon}>
        <label>
         <AddPhotoAlternateIcon/>
         <input style={displayButton} type="file" id ="image" onChange={(event)=>{uploadImage(event)}}/>
         </label>
        </IconButton>
      </div>
    </div>
  )
}

export default ImageArea;