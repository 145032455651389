import React from 'react';
import { PieChart, Pie,Label, Cell, Tooltip} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    width: "300px",
    height:"300px",
    marginRight: 20,
    marginTop: 20,
    padding:5,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
  },

  data:{
   fontSize: 40,
   marginLeft:40,
  },
  pos: {
    marginBottom: 12,
  },
  
});

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042',
'#0088FE', '#00C49F', '#FFBB28', '#FF8042',
'#0088FE', '#00C49F', '#FFBB28', '#FF8042',
'#0088FE', '#00C49F', '#FFBB28', '#FF8042',
'#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const PiChart =(props)=>{
  const classes = useStyles();

    return (
      <Card className={classes.root}>
         <Typography className={classes.title} color="textSecondary" gutterBottom>
         {props.title}
        </Typography>
       
        <Divider variant="middle" />
      
      <PieChart width={300} height={300} >
      <Pie
            dataKey="value"
            nameKey="name"
            isAnimationActive={false}
            data={props.data}
            cx="50%"
            cy="50%"
            outerRadius={100}
            innerRadius={60}
            fill="#8884d8"
            label
          >
          <Label width={30} position="center">
             {props.label}       
          </Label>

         {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
          <Tooltip />
         
        </PieChart>
      </Card>
    );
  
}

export default PiChart;
