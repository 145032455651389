import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import Button from '@material-ui/core/Button';
// import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PDatePicker from "./PDatePicker";
import SecondsTimePicker from "./SecondsTimePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import SelectBox from "../../Tools/SelectBox";
import ImageArea from "./ImageArea";
import ImagePreview from './ImagePreview';
import "../../Styles/Styles.css";
// import {getStudentsEachMonth} from "../../reducks/StudentsEachmonth/selector";
// import { SpeakerGroupSharp } from '@material-ui/icons';




const UploadPaymentData = (props) => {


  const studentsEachMonthRef = db.collection("studentsEachMonth")
  // const [open, setOpen] = useState(false);
  const [paymentDate, setPaymentDate] = useState(null);
  const [Bank, setBank] = useState(null);
  const [images, setImages] = useState({ id: null, path: null });


  const Krungsri = {
    id: "Krungsri",
    name: "Krungsri",
    accountNumber: "769-1-05167-0",
    logo: "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2Fkrungsri_logo.png?alt=media&token=416bd477-a820-437f-b9be-363e5e897a6f"
  }

  const BangkokBank = {
    id: "Bangkok_Bank",
    name: "Bangkok Bank",
    accountNumber: "030-8-05992-2",
    logo: "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2FBangkokBank_logo.jpeg?alt=media&token=63ba1782-80f5-4042-81e2-6abea87c1efd"
  }
  const Cash = {
    id: "Cash",
    name: "Cash",
    accountNumber: "",
    logo: "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2Fthailand-baht.svg?alt=media&token=e7ea8ca9-ce2f-4d21-a9ba-c14868c4fd63"
  }
  const options = [Krungsri, BangkokBank, Cash];

  const BankInfo = options.find((data) => data.id === Bank);




  useEffect(() => {
    if (props.student !== null) {
      if (props.student.isPaid === "paid") {
        if (props.student.paymentDate !== null) {
          setPaymentDate(new Date(props.student.paymentDate.seconds * 1000 + props.student.paymentDate.nanoseconds / 1000000));
          setBank(props.student.bank.id);
          setImages(props.student.images);
        }
      } else if (props.student.isPaid == "submitted") {
        setImages(props.student.images);
      }
    }
  }, [props.student])

  const handleClose = () => {
    props.onChange(false);
    // setPaymentDate(null);
    // setBank(null);
    // setImages(null);
  };

  const handleClear = async (student) => {

    const studentEdit = student;
    const monthId = studentEdit.monthId;
    const stuId = studentEdit.id

    studentEdit.paymentDate = null;
    const temp = { id: null, path: null }
    studentEdit.images = temp

    studentEdit.bank.accountNumber = null;
    studentEdit.bank.id = null;
    studentEdit.bank.logo = null;
    studentEdit.bank.name = null;
    studentEdit.isPaid = "not yet";

    await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(studentEdit, { merge: true });
    console.log(studentEdit);

    handleClose();
  }

  const handleUpload = async (student) => {

    const studentEdit = student;
    const monthId = studentEdit.monthId;
    const stuId = studentEdit.id

    studentEdit.paymentDate = paymentDate;
    studentEdit.images = images;

    if (BankInfo !== undefined) {
      studentEdit.bank = BankInfo;
    }

    studentEdit.isPaid = "paid";

    console.log(studentEdit);

    await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).set(studentEdit, { merge: true });

    handleClose();
    //  props.onCount(props.count+1)
  }

  //  const check =()=>{
  //    props.students.forEach((data)=>{
  //      if(data.images.path ===null){
  //      console.log(data.parentName, data.givenName, data.images)
  //    }})

  //  }

  //  check();

  const Loading = () => {
    if (props.student !== null) {
      return (<div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">{"Upload Payment Data of "
            + props.student.familyName + " " + props.student.givenName}</DialogTitle>

          <div className={'panel'}>

            <div className={'left'}>
              {images && (<ImagePreview path={images.path} id={images.id} />)}
            </div>

            <div className={'right'}>
              <ImageArea student={props.student} selectedMonth={props.selectedMonth} images={images} setImages={setImages} />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <PDatePicker value={paymentDate} onChange={setPaymentDate} />
                <SecondsTimePicker value={paymentDate} onChange={setPaymentDate} />
              </MuiPickersUtilsProvider>

              <SelectBox label="Select Bank" options={options} value={Bank} select={setBank} />
            </div>

          </div>

          <DialogActions>
            <Button onClick={() => { handleClear(props.student, props.listId, props.selectedMonth) }} color="primary">
              Clear
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => { handleUpload(props.student, props.listId, props.selectedMonth) }} color="primary" autoFocus>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>)
    } else {
      <></>
    }
  }


  return (
    <div>
      {Loading()}
    </div>
  );
}

export default UploadPaymentData;