import { FirebaseTimestamp, db} from '../../firebase/firebase';
import {fetchStudentsAction} from './action';
import {deleteStudentsAction} from './action';

const studentsRef = db.collection("students")

export const deleteStudents =(id)=>{
  return async(dispatch,getState) =>{
    studentsRef.doc(id).delete()
     .then(()=>{
        const prevStudents  = getState().students.list;
        const nextStudents  = prevStudents.filter(data=> data.id !== id)
        dispatch(deleteStudentsAction(nextStudents));
     })
 }
}

export const fetchStudents =()=>{
  return async(dispatch) =>{
      studentsRef.orderBy('created_at', 'asc').get()
       .then(snapshots=>{
         const studentsList = []
         snapshots.forEach(snapshot=>{
           const data = snapshot.data();
           studentsList.push(data)
         })
         dispatch(fetchStudentsAction(studentsList))
         
       })
  }
}

export const saveStudent = (parentName,familyName,email,phoneNumber,address,grade,gender,givenName,familyNameJapanese,givenNameJapanese,birthDay,schoolName,pickUp,course,isSibling) =>{
  return async(dispatch) =>{
    const timestamp = FirebaseTimestamp.now()
    const ref = studentsRef.doc();
    const id = ref.id;

    const data = {
      id : id,
      parentName:parentName,
      familyName:familyName,
      email:email,
      phoneNumber:phoneNumber,
      address:address,
      grade:grade,
      gender:gender,
      givenName:givenName,
      familyNameJapanese:familyNameJapanese,
      givenNameJapanese:givenNameJapanese,
      birthDay:birthDay,
      schoolName:schoolName,
      pickUp:pickUp,
      course:course,
      isSibling:isSibling,
      updated_at:timestamp
    }

   
    data.created_at = timestamp

    return studentsRef.doc(id).set(data, {merge:true}) 
    .then(() => {
}).catch((error)=>{
  throw new Error(error)
})
  }
}

export const changeStudent = (id,parentName,familyName,email,phoneNumber,address,grade,gender,givenName,familyNameJapanese,givenNameJapanese,birthDay,schoolName,pickUp,course,isSibling) =>{
  return async(dispatch) =>{
    const timestamp = FirebaseTimestamp.now()
    
    const data = {
      id : id,
      parentName:parentName,
      familyName:familyName,
      email:email,
      phoneNumber:phoneNumber,
      address:address,
      grade:grade,
      gender:gender,
      givenName:givenName,
      familyNameJapanese:familyNameJapanese,
      givenNameJapanese:givenNameJapanese,
      birthDay:birthDay,
      schoolName:schoolName,
      pickUp:pickUp,
      course:course,
      isSibling:isSibling,
      updated_at:timestamp
    }


    return studentsRef.doc(id).set(data, {merge:true}) 
    .then(() => {
}).catch((error)=>{
  throw new Error(error)
})
  }
}




