import { FirebaseTimestamp, db} from '../../firebase/firebase';
import {fetchClassesAction, deleteClassAction}  from './action';

const classesRef = db.collection("classes")


export const deleteClass =(id)=>{
   return async(dispatch,getState) =>{
     classesRef.doc(id).delete()
      .then(()=>{
         const prevClasses  = getState().classes.list;
         const nextClasses  = prevClasses.filter(data=> data.id !== id)
         dispatch(deleteClassAction(nextClasses));
      })

  
  }
}

export const fetchClasses =()=>{
   return async(dispatch) =>{
       classesRef.orderBy('created_at', 'asc').get()
        .then(snapshots=>{
          const classList = []
          snapshots.forEach(snapshot=>{
            const data = snapshot.data();
            classList.push(data)
          })
          dispatch(fetchClassesAction(classList))
        })
   }
}

export const saveClass = (isMain,isTemp,isDiscount,classname,cPrice) =>{
  return async(dispatch) =>{
    const timestamp = FirebaseTimestamp.now()
    const ref = classesRef.doc();
    const id = ref.id;

    const data = {
      id : id,
      isMain: isMain,
      isTemp:isTemp,
      isDiscount:isDiscount,
      classname:classname,
      cPrice:parseInt(cPrice,10),
      updated_at:timestamp
    }

   
    data.created_at = timestamp

    return classesRef.doc(id).set(data, {merge:true}) 
    .then(() => {
       
}).catch((error)=>{
  throw new Error(error)
})
  }
}

export const ChangeClass = (id,isMain,isTemp,isDiscount,classname,cPrice) =>{
  return async(dispatch) =>{
    const timestamp = FirebaseTimestamp.now()
    
    const data = {
      id : id,
      isMain: isMain,
      isTemp:isTemp,
      isDiscount:isDiscount,
      classname:classname,
      cPrice:parseInt(cPrice,10),
      updated_at:timestamp
    }


    return classesRef.doc(id).set(data, {merge:true}) 
    .then(() => {
}).catch((error)=>{
  throw new Error(error)
})
  }
}


