import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from 'react';
import Card from '@material-ui/core/Card';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";



const SortableItem = (props) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const handleDoubleClickOpen = () => {
        props.setOpenModalClassEdit(true);
        props.setSelectedClass(props.content);
    }


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Card
                className={"insert_card_class"}
                onDoubleClick={handleDoubleClickOpen}
            >
                <div>
                    {props.content.classname}
                </div>

                <div className={'horizontal'} >
                    <div className={"insert_card_mergin"} >
                        {props.content.cPrice}
                    </div>

                    <div className={"insert_card_mergin"}>
                        <ArrowForwardIcon
                            fontSize={'small'}
                        />
                    </div>

                    {props.content.isTemp
                        ? <div className={"insert_card_mergin"} >
                            {props.content.cPrice}
                        </div>
                        : <div className={"insert_card_mergin"} >
                            {Math.ceil(props.content.cPrice * props.scaleFactor)}
                        </div>}

                </div>

            </Card>

        </div>
    )

}

export default SortableItem;