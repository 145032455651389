
import {TextInput,PButton} from "../Tools/Index";
import React, { useState,useCallback } from "react";
import {signUp} from "../reducks/users/operations";
import {useDispatch} from 'react-redux';


const SignUp=()=>{
    const dispatch = useDispatch()
    const [username, setUsersname] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] =useState("");

    const inputUsername = useCallback((e)=>{
      setUsersname(e.target.value)
 },[setUsersname])
  
    const inputEmail = useCallback((e)=>{
         setEmail(e.target.value)
    },[setEmail])
  
    const inputPassword = useCallback((e)=>{
      setPassword(e.target.value)
  },[setPassword])

  const inputConfirmPassword = useCallback((e)=>{
    setConfirmPassword(e.target.value)
},[setConfirmPassword])


  return (
    <div>
      <h2> Account Registration </h2>
      <TextInput 
      fullwidth={true} label={"Username"} multiline={false}
      required={true}  rows={1}  value={username} type={"text"} onChange={inputUsername}/>
      <TextInput 
      fullwidth={true} label={"Email"} multiline={false}
      required={true}  rows={1}  value={email} type={"email"} onChange={inputEmail}/>
     <TextInput 
      fullwidth={true} label={"Password"} multiline={false}
      required={true}  rows={1}  value={password} type={"password"} onChange={inputPassword}/>

      <TextInput 
            fullwidth={true} label={"Confirm Password"} multiline={false}
            required={true}  rows={1}  value={confirmPassword} type={"password"} onChange={inputConfirmPassword}/>
       <div className={"position_margin"}/>
      <PButton label={"Make Account"} onClick={()=>dispatch(signUp(username,email, password,confirmPassword))}/>

    </div>
  )
}

export default SignUp;