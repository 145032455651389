export const FETCH_CLASSES = 'FETCH_CLASSES';


export const fetchClassesAction = (classes) => {
  return {
       type:"FETCH_CLASSES",
       payload: classes
  }
}

export const DELETE_CLASSES = 'DELETE_CLASSES';

export const deleteClassAction = (classes) => {
  return {
       type:"DELETE_CLASSES",
       payload: classes
  }
}
  