import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CheckboxCarSchedule from './CheckBoxCarSchedule';



const BasicGrid = (props) => {


    return (

        <Box sx={{ flexGrow: 1, m: 1 }}>
            <Grid container spacing={2}>

                {props.pickUpSchedule.map((data, index) => {
                    return (

                        <Grid item xs={4} key={data.day + index}>

                            <CheckboxCarSchedule
                                index={index}
                                data={data}
                                pickUpSchedule={props.pickUpSchedule}
                                setPickUpSchedule={props.setPickUpSchedule}
                                pickUp={props.pickUp}
                                setPickUp={props.setPickUp}
                            />

                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
}

export default BasicGrid;