import React from 'react';
import { BarChart, Bar,  XAxis, YAxis, CartesianGrid, Tooltip, } from 'recharts';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles({
  root: {
    minWidth: 250,
    width: "950px",
    height:"300px",
    marginRight: 20,
    marginTop: 20,
    padding:5,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
  },

  data:{
   fontSize: 40,
   marginLeft:40,
  },
  pos: {
    marginBottom: 12,
  },
  
});


const BarGraph=(props)=> {
 
  const classes = useStyles();

    return (
      
      <Card className={classes.root}>
         <Typography className={classes.title} color="textSecondary" gutterBottom>
         Statistic Data of Each Course {props.title}
        </Typography>
        
        <Divider variant="middle"  />
        <BarChart
          width={900}
          height={250}
          data={props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Bar yAxisId="left" dataKey="NofP" fill="#8884d8" />
          <Bar yAxisId="right" dataKey="Sales" fill="#82ca9d" />
        </BarChart>
      </Card>
    )
}

export default BarGraph;