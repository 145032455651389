import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ModalWindowEdit from "./ModalWindowEdit";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ModalDeleteStudent from './ModalDeleteStudent';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});



const CustomizedTable = (props) => {

  const classes = useStyles();



  const [open, setOpen] = useState(false);
  const [editStudent, setEditStudent] = useState(null);
  const [deleteStudent, setDeleteStudent] = useState(null);
  const [students, setStudents] = useState([]);
  const [openModalDeleteStudent, setOpenModalDeleteStudent] = useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setEditStudent(data)
  };


  const handleClickDeleteStudent = (data) => {
    setDeleteStudent(data);
    setOpenModalDeleteStudent(true);
  }

  const filterClass = () => {
    let tempStu = props.filteredStudents

    tempStu.forEach(data => {
      let preCourses = [];
      data.course.forEach(value => {
        const item = props.lecs.filter((v) => value.id.includes(v.id));
        preCourses = [...preCourses, ...item];
      }
      )

      const courses = preCourses.filter((cours) => {
        return cours.cPrice !== 0;
      }
      )

      data.course = [];
      data.course = courses;
    })
    setStudents(tempStu);
  }

  useEffect(() => {
    filterClass();
  }, [props.filteredStudents, props.allStu])




  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Student</StyledTableCell>
              <StyledTableCell align="left">Grade</StyledTableCell>
              <StyledTableCell align="left">Class</StyledTableCell>
              <StyledTableCell align="left">PickUp</StyledTableCell>
              <StyledTableCell align="left">Address</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Edit</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {students.map((data) => (
              <StyledTableRow key={data.id}>
                <StyledTableCell align="left">{data.familyNameJapanese + " " + data.givenNameJapanese}</StyledTableCell>
                <StyledTableCell align="left">{data.grade}</StyledTableCell>
                <StyledTableCell align="left">
                  {
                    data.course.map((temp) => {
                      return temp.classname
                    }).join(",")
                  }

                </StyledTableCell>
                <StyledTableCell align="left">{data.pickUp}</StyledTableCell>
                <StyledTableCell align="left">{data.address}</StyledTableCell>
                <StyledTableCell align="left">{data.email}</StyledTableCell>
                <StyledTableCell align="left">
                  <CreateOutlinedIcon onClick={() => { handleClickOpen(data) }} />
                </StyledTableCell>
                <StyledTableCell align="left"><DeleteOutlinedIcon onClick={() => { handleClickDeleteStudent(data) }} /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editStudent && (
        <ModalWindowEdit
          onDataChange={props.onDataChange}
          setOpen={setOpen}
          open={open}
          title={"Student Edit form"}
          student={editStudent}
          allStu={props.allStu}
          lecs={props.lecs}
          setAllStu={props.setAllStu}
        />
      )}

      {deleteStudent && (
        <ModalDeleteStudent
          open={openModalDeleteStudent}
          setOpen={setOpenModalDeleteStudent}
          student={deleteStudent}
          allStu={props.allStu}
          setAllStu={props.setAllStu}
        />
      )}
    </div>
  );
}

export default CustomizedTable