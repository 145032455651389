import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ModalWindow from '../View/ClassEdit/ModalWindow';
import '../Styles/Styles.css';


const useStyles = makeStyles({
  root: {
    minWidth: 250,
    marginRight: 20,
    marginTop: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  
});

const Tyle= (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Name 
        </Typography>
        <Typography variant="h5" component="h2">
           {props.classname}
        </Typography>


         <Typography className={classes.title} color="textSecondary" gutterBottom>
          price
        </Typography>
        <Typography variant="h5" component="h2">
            {props.cPrice} Baht
        </Typography>
      </CardContent>
   
       <div className={'button_design'}>
        <ModalWindow isMain={props.isMain} isTemp={props.isTemp} isDiscount={props.Discount}
                     classname={props.classname} cPrice={props.cPrice} id={props.id}/>

        <Button className={'button_margin'} variant={'outlined'} color='secondary'  onClick={props.onClick1}>Delete</Button>
       </div>
       
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        </Typography>
    </Card>
  );
}

export default Tyle