import React from 'react';
import Router from "./Router";
import Header from './View/header/Header';
import './Styles/Styles.css';



const App=() => {

  return (
  <div>
      <Header/>
    <main className='c-main'> 
      <Router/>
    </main>
  </div>
  )
}

export default App;
