import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeStudentsEachMonth } from "../../reducks/StudentsEachmonth/operations"
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import "../../Styles/Styles.css";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { SwitchLabel } from '../../Tools/Index';
import 'react-notifications/lib/notifications.css';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';

const createNotification = (progress, name, type) => {
  switch (type) {
    case 'info':
      NotificationManager.info('All message have been sent.', 'Succeeded', 10000);
      break;
    case 'success':
      NotificationManager.success('Success message' + " " + progress, name, 20000, pushNoticication(), true);
      break;
    case 'warning':
      NotificationManager.warning(
        'Warning message',
        'Close after 3000ms',
        3000
      );
      break;
    case 'error':
      NotificationManager.error('error code:' + progress, name, 20000, () => {
        alert('Eat Shit,Asshole!!');
      });
      break;
    default:
      console.log("Couldn't find notification type");
      break;
  }
};

const pushNoticication = () => {
  console.log('success')
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 20000,
    color: '#fff',
  },
}));



const SendEmailToAll = (props) => {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAttach, setIsAttach] = useState(true);
  const [isAll, setIsAll] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
  }, [isLoading]);

  const inputMessage = useCallback((e) => {
    setMessage(e.target.value)
  }, [setMessage]);

  const inputSubject = useCallback((e) => {
    setSubject(e.target.value)
  }, [setSubject]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Clear = () => {
    handleClose()
  }

  const handleToggle = () => {
    setIsAttach(!isAttach);
  }

  const handleToggleAll = () => {
    setIsAll(!isAll);
  }

  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const handleSend = async (listId, students, allStu, context, title, isAttach, isAll) => {
    //  sendMail()
    setIsLoading(true);
    const windowSize = 5;
    const AttachFile = isAttach;
    const subject = title;
    const message = context;
    const isMail = true;
    let sentStus = []

    if (isAll) {
      // const unSentStus = allStu.filter((stu)=>{
      //     let total = stu.course.reduce((prev,cur)=>{
      //       return prev + parseInt(cur.cPrice)
      //     },0)

      //     if(total !==0){
      //       return stu;
      //     }
      // })

      const studentsChunks = sliceByNumber(allStu, windowSize)
      for (let i = 0; i < studentsChunks.length; i++) {
        const Estudents = studentsChunks[i];
        console.log(i, studentsChunks.length)
        const responses = await Promise.all(Estudents.map(async (stu, index) => {
          const student = stu;
          console.log(stu.givenName, "before")
          const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/sendEmail", {
            method: 'POST',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({ listId, student, subject, message, isMail, AttachFile })
          });
          const resData = response.json();
          const NofStus = allStu.length
          const progress = (windowSize * i + index + 1) + "/" + NofStus
          const Name = stu.parentName + " " + stu.givenName

          console.log(response.status)
          console.log(resData);


          if (response.status === 200) {
            createNotification(progress, Name, 'success')
            stu.isSent = "sent";

          } else {
            stu.isSent = "undelivered";
            createNotification(response.status, Name, 'error')
          }
          return resData
        }))
        console.log(responses);
        sentStus = [...sentStus, ...responses]
      }

      upToDataBase(sentStus)
    } else {

      const studentsToSend = students.filter((stu) => {
        let total = stu.course.reduce((prev, cur) => {
          if (cur.cPrice !== "") {
            return prev + parseInt(cur.cPrice)
          } else {
            return prev
          }
        }, 0)
        console.log(total);

        if (total !== 0) {
          return stu;
        }
      })

      const studentsChunks = sliceByNumber(studentsToSend, windowSize)

      console.log(studentsToSend);

      for (let i = 0; i < studentsChunks.length; i++) {
        const Estudents = studentsChunks[i];

        const responses = await Promise.all(Estudents.map(async (stu, index) => {
          const student = stu;
          const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/sendEmail", {
            method: 'POST',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({ listId, student, subject, message, isMail, AttachFile })
          });
          const resData = response.json();
          const NofStus = students.length
          const progress = (windowSize * i + index + 1) + "/" + NofStus
          const Name = stu.parentName + " " + stu.givenName

          console.log(resData);

          if (response.status === 200) {
            stu.isSent = "sent";
            createNotification(progress, Name, 'success')
            return stu;
          } else {
            stu.isSent = "undelivered";
            createNotification(response.status, Name, 'error')
            return stu;
          }

        }))
        sentStus = [...sentStus, ...responses]
      }
      upToDataBase(sentStus)
    }
    createNotification("a", "b", 'info')
    setIsLoading(false);
    handleClose();
  };

  const upToDataBase = (sentStus) => {
    let tempStu = props.allStu;
    const changedStus = sentStus;
    const listId = props.listId;
    const selectedMonth = props.selectedMonth;

    tempStu.forEach((stu) => {
      changedStus.forEach((data) => {
        {
          if (stu.id === data.id) {
            stu = data
          }
        }
      })
    })
    // console.log(listId,selectedMonth,tempStu);
    dispatch(changeStudentsEachMonth(listId, selectedMonth, tempStu))
  }

  return (
    <div>

      <EmailOutlinedIcon variant="outlined" onClick={handleClickOpen} />


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">Sending Email to ...</DialogTitle>


        <form style={{ width: "80%" }}>
          {/* <h1>Sending Message to Bulk Students...</h1> */}

          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="name">To Bulk </InputLabel>
            <Input id="name" type="text" />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="title">Title</InputLabel>
            <Input id="title" type="text"
              onChange={inputSubject} />
          </FormControl>


          <SwitchLabel checked={isAttach} onChange={handleToggle} label={"Attach Invoice?"} />

          <SwitchLabel checked={isAll} onChange={handleToggleAll} label={"Send to all Stu?"} />

          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="email">Message</InputLabel>
            <Input id="email" multiline rows={10}
              onChange={inputMessage} />
          </FormControl>

          <Button variant="contained" color="primary" size="medium" onClick={() => handleSend(props.listId,
            props.students,
            props.allStu,
            message,
            subject,
            isAttach,
            isAll)}>
            Send
          </Button>
        </form>

      </Dialog>

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
        <p> Now sending Emails. It may take while...</p>
      </Backdrop>

      <NotificationContainer />
    </div>
  );
}

export default SendEmailToAll;