
import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { PButton } from '../../Tools/Index';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { PDFDocument } from 'pdf-lib';
import SelectMonth from "./SelectMonth"
import "../../Styles/Styles.css";



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 5000,
    color: '#fff',
  },
}));

const PublishRvFinal = (props) => {

  const [open, setOpen] = useState(false);
  const [publishMonth, setPublishMonth] = useState("")
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };





  // useEffect(() => {
  //   const func = async () => {
  //     dispatch(fetchStudentsEachMonth())
  //    //  setStudentsSelected(fc)
  //   }
  //   func();
  // }, []);



  const [rvStartN, setRvStartN] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  // useEffect(() => {

  //   console.log(props.allStu)

  // }, [props.selectedMonth,isLoading]);

  const inputRvStartN = useCallback((e) => {
    setRvStartN(e.target.value)
  }, [setRvStartN])



  const handlePublishBuckNumber = async (AllStudents, rvStartN, month, pMethod) => {
    const date = new Date(month);
    const dateMonth = date.getMonth();
    const dateYear = date.getFullYear();

    console.log(AllStudents);

    const tempStu = AllStudents.map((data) => {
      const studentsEach = {}
      studentsEach.savedMonth = data.savedMonth;
      const id = data.id
      studentsEach.id = data.id;

      studentsEach.students = data.students.filter((stu) => {
        if (stu.paymentDate !== null) {

          const pDate = new Date(stu.paymentDate.seconds * 1000);
          const pDateMonth = pDate.getMonth();
          const pDateYear = pDate.getFullYear();

          return pDateMonth === dateMonth && pDateYear === dateYear;
        }
      })

      studentsEach.students.forEach((stu) => {
        stu.monthId = id
      })
      return studentsEach
    });


    let tempTempStu = tempStu.map((data) => {
      return data.students
    }).reduce((prev, current) => {
      return prev.concat(current)
    }, [])

    let finalStu = tempTempStu.filter((v) => v.bank.name === pMethod)

    finalStu.sort((prev, current) => {
      if (prev.paymentDate < current.paymentDate) {
        return -1;
      } else {
        return 1;
      }
    })

    const groups = groupBy(finalStu, r => r.paymentDate.seconds);
    const paidStudents = Object.values(groups)

    setIsLoading(true)

    await DownloadPdfRv(paidStudents, rvStartN, pMethod);

  };

  const groupBy = (array, getKey) =>
    array.reduce((obj, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      (obj[key] || (obj[key] = [])).push(cur);
      return obj;
    }, {});



  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const DownloadPdfRv = async (data, rvStartN, pMethod) => {
    const students = data;
    const rvN = rvStartN;
    const pM = pMethod

    const windowSize = 1;
    const studentsChunks = sliceByNumber(students, windowSize)

    let pdfBuffers = [];
    for (let i = 0; i < studentsChunks.length;) {
      const chunkedStu = studentsChunks[i]

      let pdfBuffer = await Promise.all(chunkedStu.map(async (stu, index) => {
        console.log(stu);
        const id = windowSize * i + index + 1;
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/makeRvList", {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ stu, id, rvN, pM })
        });

        console.log(id)
        const resData = response.arrayBuffer();
        return resData;
      }))

      console.log(pdfBuffer, pdfBuffer[0].byteLength)

      if (pdfBuffer[0].byteLength > 3000) {
        i = i + 1
        pdfBuffers = [...pdfBuffers, ...pdfBuffer]
      } else {
        i = i
      }

    }

    await mergePdfs(pdfBuffers, pMethod);
    handleClose();
    setIsLoading(false);
  };

  const mergePdfs = async (pdfBuffers, pMethod) => {

    const pdfsBufferList = pdfBuffers
    // const windowSize = 100;
    // const chunckedBuffers =  sliceByNumber(pdfsBufferList,windowSize);
    // for(let j = 0; j<chunckedBuffers.length; j++){
    const pdfDoc = await PDFDocument.create();
    //    const  chunkedBuffer = chunckedBuffers[j] 
    for (let i = 0; i < pdfsBufferList.length; i++) {
      console.log(i)
      const firstDocument = pdfsBufferList[i];
      const firstDonnerPdfDoc = await PDFDocument.load(firstDocument)
      const pages = firstDonnerPdfDoc.getPageIndices()
      const copiedPages = await pdfDoc.copyPages(firstDonnerPdfDoc, pages)

      copiedPages.map((page, order) => {
        if (order !== 0) {
          pdfDoc.addPage(page)
        }
      })
    }
    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, pMethod)
  }
  // }

  const download = (pdfBytes, pMethod) => {
    let blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const fileName = "summary_" + pMethod + ".pdf"
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  return (

    <div>
      <PButton label={"Publish RV"} variant={"outlined"} color="primary" onClick={handleClickOpen} />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">Publication</DialogTitle>
        <DialogContent>

          <div className={"tyle_arrangement"}>
            <TextField
              id="outlined-number"
              label="RV start from"
              type="number"
              value={rvStartN}
              onChange={inputRvStartN}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />

            <SelectMonth onChange={setPublishMonth}
              value={publishMonth} lists={props.MonthList} />

          </div>

          <div className={"tyle_arrangement"}>
            <Button
              variant={"outlined"}
              color="secondary"
              onClick={() => { handlePublishBuckNumber(props.studentsAllMonth, rvStartN, publishMonth, "Krungsri") }}>
              Krungsri
            </Button>

            <PButton label={"Bangkok Bank"}
              variant={"outlined"}
              color="primary"
              onClick={() => { handlePublishBuckNumber(props.studentsAllMonth, rvStartN, publishMonth, "Bangkok Bank") }} />

            <PButton label={"Cash"}
              variant={"outlined"}
              onClick={() => { handlePublishBuckNumber(props.studentsAllMonth, rvStartN, publishMonth, "Cash") }} />
          </div>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
        <p> Now Printing...</p>
      </Backdrop>
    </div>
  );
}



export default PublishRvFinal;