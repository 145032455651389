import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import * as serviceWorker from './serviceWorker';
import createStore from './reducks/Store/store'
import * as History from 'history';
import {ConnectedRouter} from "connected-react-router";

const history = History.createBrowserHistory();
const store = createStore(history);
ReactDOM.render(
 <Provider store={store}>
   <ConnectedRouter history={history}>
    <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
