import React from 'react';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { yellow, green, red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import "../../Styles/Styles.css";

const Compare = (props) => {
  const dif = props.dif
  if (dif > 0) {
    return (
      <div className="indicator">
        <TrendingUpIcon style={{ color: green['A200'], fontSize: 40 }} />
        <Typography variant="h5" component="h4">  {dif} </Typography>
      </div>
    )
  } else if (dif === 0) {
    return (
      <div className="indicator">
        <TrendingFlatIcon style={{ color: yellow[400], fontSize: 30 }} />
        <Typography variant="h5" component="h4"> {dif}  </Typography>
      </div>
    )

  } else {
    return (
      <div className="indicator">
        <TrendingDownIcon style={{ color: red["A400"], fontSize: 30 }} />
        <Typography variant="h5" component="h4"> {dif}</Typography>
      </div>
    )
  }



};

export default Compare;