import * as Actions from "./action";
import initialState from "../Store/initialState";

export const userReducer=(state=initialState.users, action) =>{
  switch (action.type){

    case Actions.SIGN_IN:
      return{
      ...state, 
      ...action.payload
      }
    case Actions.SIGN_OUT:
     return{
       ...action.payload
  }
  
    default :
    return state
  }
}