import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextInput from '../../Tools/TextInput';
import { SwitchLabel } from "../../Tools/Index";
import { db, FirebaseTimestamp } from "../../firebase/firebase";




const ModalWindow = (props) => {

  const classesRef = db.collection('classes');


  const [classname, setClassName] = useState(props.content.classname);
  const [cPrice, setClassPrice] = useState(props.content.cPrice);
  const [isMain, setIsMain] = useState(props.content.isMain);
  const [isTemp, setIsTemp] = useState(props.content.isTemp);
  const [isDiscount, setIsDiscount] = useState(props.content.isDiscount);

  useEffect(() => {
    setClassName(props.content.classname);
    setClassPrice(props.content.cPrice);
    setIsMain(props.content.isMain);
    setIsTemp(props.content.isTemp);
    setIsDiscount(props.content.isDiscount);
  }, [props.content])


  const handleClose = () => {
    props.setOpen(false)
  };

  const handleClickSave = async () => {
    const timestamp = FirebaseTimestamp.now()


    const data = {
      id: props.content.id,
      classname: classname,
      cPrice: parseInt(cPrice, 10),
      isMain: isMain,
      isTemp: isTemp,
      isDiscount: isDiscount,
      order: props.content.order,
      updated_at: timestamp
    }

    let tempLecs = props.lecs.map((lec) => {
      if (data.id === lec.id) {
        return data;
      } else {
        return lec;
      }
    })
    props.setLecs(tempLecs);
    await classesRef.doc(props.content.id).set(data, { merge: true })
    handleClose();
  }

  const handleClickDelete = async () => {
    props.setOpenCheckDelete(true);
    // handleClose();
  }

  const toggleIsMain = () => {
    setIsMain(!isMain)
  }

  const toggleIsTemp = () => {
    setIsTemp(!isTemp)
  }

  const toggleIsDiscount = () => {
    setIsDiscount(!isDiscount)
  }

  const inputClassName = useCallback((e) => {
    setClassName(e.target.value)
  }, [setClassName]);

  const inputClassPrice = useCallback((e) => {
    setClassPrice(e.target.value)
  }, [setClassPrice]);



  // const Editing = (event, id, isMain, isTemp, isDiscount, classname, cPrice) => {
  //   dispatch(ChangeClass(id, isMain, isTemp, isDiscount, classname, cPrice));
  //   handleClose(event);
  // }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogContent>

        <DialogContentText >
          Class ID:{props.content.id}
        </DialogContentText>


        <DialogContentText>
          Change Name
        </DialogContentText>

        <TextInput
          required={false}
          type={'text'}
          value={classname}
          onChange={inputClassName}
        />

        <DialogContentText >
          Change Price
        </DialogContentText>

        <TextInput
          required={false}
          type={'number'}
          value={cPrice}
          onChange={inputClassPrice}
        />

      </DialogContent>


      <div className='class_form'>
        <div className='class_form_buttons'>
          <SwitchLabel
            checked={isMain}
            color={"primary"}
            label={"Main couse?"}
            onChange={toggleIsMain}
          />

          <SwitchLabel
            checked={isTemp}
            label={"Temporary?"}
            onChange={toggleIsTemp}
          />

          <SwitchLabel
            checked={isDiscount}
            label={"Discount?"}
            onChange={toggleIsDiscount}
          />
        </div>


        <div>

          <Button
            onClick={handleClickSave}
            color="primary">
            Change
          </Button>

          <Button onClick={handleClickDelete}
            color="secondary">
            Delete
          </Button>

        </div>
      </div>
    </Dialog>

  );
}

export default ModalWindow

