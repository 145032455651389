import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    width: "300px",
    height:"300px",
    marginRight: 20,
    marginTop: 20,
    padding:5,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
  },

  contents: {
    fontSize: 14,
  },

  data:{
   fontSize: 40,
   marginLeft:40,
  },
  pos: {
    marginBottom: 12,
  },
  
});



const MessageBox =(props)=>{
  const classes = useStyles();

    return (
      <Card className={classes.root}>
         <Typography className={classes.title} color="textSecondary" gutterBottom>
         Message Box
        </Typography>
        <Divider variant="middle" /> 
        {props.notPaid.map((stu,index)=>
          <div key ={index}>
          <Typography className={classes.contents} color="error" gutterBottom>
            {stu.familyName} {stu.givenName} has not paid for {props.monthSecondLast} 
         </Typography>
          <Divider/>
          </div>
        )}    
        {props.birthDay.map((stu,index)=>
          <div key={index}>
            <Typography className={classes.contents} color="primary" gutterBottom>
            Today is {stu.familyName}  {stu.givenName}'s Birthday!!! 
            </Typography>
             <Divider/>
          </div>
        )}
      </Card>
    );
  
}

export default MessageBox;