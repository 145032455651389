import React,{useState,useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {PButton} from '../../Tools/Index';
import DialogActions from '@material-ui/core/DialogActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 200000,
    color: '#fff',
  },
}));

 

const RecieptPDF=(props)=> {
  
  const classes = useStyles();
  const [html, setHtml] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    props.onChange(false);
  };

  useEffect(()=>{     
  },[isLoading])


  const getHtml= async ()=>{
    //  sendMail() 
       const listId = props.listId;
       const student = props.student
       const isReciept = true;

    const response = await fetch(process.env.REACT_APP_API_BASE_URL+"/makeHtml", { 
      method: 'POST', 
      headers: { 
          'Content-type': 'application/json'
      }, 
      body: JSON.stringify({listId,student,isReciept}) 
  }); 
    const resData = await response.json(); 
    
    return  setHtml(resData)
};

const DownloadPDF= async ()=>{
     setIsLoading(true)
       const listId = props.listId;
       const student = props.student;
       const isMail = false;
       const isReciept = true;

    const response = await fetch(process.env.REACT_APP_API_BASE_URL+"/sendEmail", { 
      method: 'POST', 
      headers: { 
          'Content-type': 'application/json',
      }, 
      body: JSON.stringify({listId,student,isMail,isReciept}) 
  }); 
    const resData = await response.blob(); 

    const d = new Date()
    const month = d.getMonth();
    const year = d.getFullYear(); 
    
     let blob = new Blob([resData],{type:'application/pdf'})

     const fileName= "Reciept_"+props.student.gender + props.student.familyName +" " + props.student.givenName +" "+month+"_"+year+".pdf"

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading(false);
     };

     if(props.open===true&&props.student.isPaid ==="paid"){
      getHtml();
    
    }
  
  
  return (
    <div>
        
        
        <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth={"xl"}
      >


      <DialogActions>
            <PButton label={"Download PDF"} variant={"outlined"} color="primary" onClick={()=>{DownloadPDF()}}/>
      </DialogActions>
    
 
     <div id="myid" dangerouslySetInnerHTML={{__html: html}} /> 
  
       <div/>
       
      </Dialog>

      <Backdrop className={classes.backdrop} open={isLoading}>
            <div>
            <CircularProgress color="inherit"/>
             <p> Making PDF.Wait a moment...</p>
            </div>
      </Backdrop>
      
      
    </div>
  );
}


export default RecieptPDF;

