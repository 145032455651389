import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';

const ClassSelector = (props) => {

    const [check, setCheck] = useState([]);

    useEffect(() => {

        let tempclassCheck = props.lecs.map((temp) => {
            if (props.course.findIndex(({ id }) => id === temp.id) !== -1) {
                return true
            } else {
                return false
            }
        })

        setCheck(tempclassCheck);
    }, [])



    const handleClick = (lec) => {
        let tempCourse = props.course;
        tempCourse.push(lec);
        props.setCourse(tempCourse)
    }


    const selectCourse = (index) => {
        console.log(check);
        const newCourseCheck = check.map((cor, order) => {
            if (order === index) {
                return !cor;
            } else {
                return cor;
            }
        })

        setCheck(newCourseCheck);

        const newSelectedCourses = props.lecs.filter((cour, index) => {
            if (newCourseCheck[index]) {
                return cour
            }
        })
        props.setCourse(newSelectedCourses);
        console.log(props.course);
    }

    return (
        <div className='tyle_arrangement'>
            {props.lecs.map((lec, index) => {
                if (!check[index]) {
                    return (
                        <div
                            key={"chip" + index}
                            className={'m-3'}
                        >
                            <Chip
                                label={lec.classname}
                                variant="outlined"
                                color="primary"
                                onClick={() => selectCourse(index)}
                            />
                        </div>

                    )
                } else {
                    return (
                        <div key={"chip" + index}
                            className={'m-3'}
                        >
                            <Chip
                                key={"chip" + index}
                                label={lec.classname}
                                color="primary"
                                onClick={() => selectCourse(index)}
                            />
                        </div>

                    )


                }

            })}
        </div>
    )

}

export default ClassSelector;