import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableNewStus from './TableNewStus';
import Backdrop from '@material-ui/core/Backdrop';



const NewStudents = () => {
    const newStudentsRef = db.collection("newStudents");
    const [isLoading, setIsLoading] = useState(false);
    const [newStudents, setNewStudents] = useState(null);

    const fetchNewStudents = async () => {
        newStudentsRef.orderBy('created_at', 'desc').get()
            .then((snapshots) => {
                const newStudentsList = [];
                snapshots.forEach((snapshot) => {
                    const data = snapshot.data();
                    newStudentsList.push(data);
                })
                setNewStudents(newStudentsList);
            })
    }

    useEffect(() => {
        fetchNewStudents();
        setIsLoading(true)
        console.log(newStudents);
    }, [])


    const contents = () => {

        if (!isLoading) {
            return (
                <Backdrop open={true}>
                    <CircularProgress color="inherit" />
                    <p> now Loading data... Please Wait. </p>
                </Backdrop>
            )
        } else if (newStudents) {
            return (
                <div>
                    <h1> New Students waiting  for approval...</h1>
                    <TableNewStus
                        newStudents={newStudents}
                        setNewStudents={setNewStudents}
                    />

                </div>

            )
        }
    }
    //   -------------------------------------------------------------------

    return (
        <div>
            {contents()}
        </div>

    )
}

export default NewStudents;