import React, { useState, useEffect } from 'react';
import { db, FirebaseTimestamp } from "../../firebase/firebase";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SelectBox } from '../../Tools/Index';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses } from '../../reducks/classes/selectors';
import { fetchClasses } from "../../reducks/classes/operations";
import DialogContentText from '@material-ui/core/DialogContentText';




const ModalWindowAddStu = (props) => {

  const studentsEachMonthRef = db.collection("studentsEachMonth");
  const [open, setOpen] = useState(false);
  const [grade, setGrade] = useState("");
  const [address, setAddress] = useState("");
  const [course, setCourse] = useState(null);
  const [pickUp, setPickUp] = useState("");
  const [email, setEmail] = useState("");
  const [isSibling, setIsSibling] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedExStu, setSelectedExStu] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [addStu, setAddStu] = useState(null);



  const toggleIsSibling = () => {
    setIsSibling(!isSibling)
  }



  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const courses = getClasses(selector);


  useEffect(() => {
    dispatch(fetchClasses())
  }, []);

  useEffect(() => {
    setStudents(props.students)

    if (props.students !== null) {
      const studentsNames = props.students.map((data) => {
        return { id: data.id, name: data.familyNameJapanese + " " + data.givenNameJapanese }
      })
      setStudentsList(studentsNames)
    }
  }, [props.students])





  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSave = async () => {
    let extraStu = addStu[0];
    const monthId = extraStu.monthId;
    const stuId = extraStu.id;
    const timestamp = FirebaseTimestamp.now()
    extraStu.created_at = timestamp;

    await studentsEachMonthRef.doc(monthId).collection('students').doc(stuId).set(extraStu)
    let newStudents = props.allStu;
    console.log(newStudents, "before");
    newStudents.push(extraStu);
    console.log(newStudents, 'after');

    props.setAllStu(newStudents);
    // dispatch(changeStudentsEachMonth(props.studentsEachMonthId, props.selectedMonth, addStus));
    handleClose();
  }

  useEffect(() => {

    if (selectedExStu !== null) {
      let student = students.filter((stu) =>
        stu.id === selectedExStu)
      let tempStu = newStudentsMonthList(student, courses)

      setAddStu(tempStu);
      setEmail(tempStu[0].email);
      setAddress(tempStu[0].address);
      setPickUp(tempStu[0].pickUp);
      setCourse(tempStu[0].course);
      setGrade(tempStu[0].grade);
    }
  }, [selectedExStu])


  const newStudentsMonthList = (student, classes) => {

    let tempStu = student;

    //  クラス情報と、生徒のクラス登録情報内容の一致の配列処理--------
    tempStu.forEach(data => {
      let courses = [];
      data.course.forEach(value => {
        const item = classes.filter((v) => value.id.includes(v.id));
        courses = [...courses, ...item];
      }
      )
      data.course = [];
      data.course = courses;
    })

    // -------------------------------------------------------------

    // 兄弟割引、併用割引、送迎費の計算+++++++++++++++++++++++++++++++++

    tempStu.forEach(data => {
      const Bank = {
        id: null,
        accountNumber: null,
        name: null,
        logo: null
      }


      const images = {
        id: null,
        path: null
      }
      data.bank = Bank;
      data.paymentDate = null;
      data.images = images;
      data.isPaid = "not yet";
      data.monthId = props.studentsEachMonthId;

      const findisSibling = data.isSibling
      if (findisSibling === true) {
        const siblingDiscountValue = data.course.reduce((prev, current) => {
          if (current.isMain === true || current.isDiscount === true) {
            return prev + -0.2 * parseInt(current.cPrice, 10);
          } else {
            return prev;
          }
        }, 0)

        const siblingDiscountValueData = {
          cPrice: siblingDiscountValue,
          classname: "兄弟割引",
          created_at: "",
          isDiscount: false,
          isMain: false,
          isTemp: true,
          id: "Sdiscount" + data.id
        }
        data.course.push(siblingDiscountValueData);

      } else {
        const findIsMain = data.course.findIndex(v => v.isMain === true)
        const discountValue = data.course.reduce((prev, current) => {
          if (current.isDiscount) {
            return prev + -0.1 * parseInt(current.cPrice, 10)
          } else {
            return prev
          }
        }, 0)

        if (findIsMain !== -1 && discountValue !== 0) {
          const discountValueData = {
            cPrice: discountValue,
            classname: "併用割引",
            created_at: "",
            isDiscount: false,
            isMain: false,
            isTemp: true,
            id: "discount" + data.id
          }
          data.course.push(discountValueData)
        }
      }
      if (data.pickUp !== "") {
        let transportationFee = data.pickUp
        transportationFee = 260 * parseInt(data.pickUp, 10)
        const transportationFeeData = {
          cPrice: transportationFee,
          classname: "送迎費",
          created_at: "",
          isDiscount: false,
          isMain: false,
          isTemp: true,
          id: "transportion" + String(data.id)
        }
        data.course.push(transportationFeeData);
      }

      for (let i = 0; i = 8 - data.course.length; i++) {
        const initialData = {
          cPrice: "",
          classname: "",
          created_at: "",
          isDiscount: false,
          isMain: false,
          isTemp: true,
          id: "courses" + String(i)
        }
        data.course.push(initialData);
      }
    }
    ); //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    return tempStu;
  };




  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add student
      </Button>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Student from Base</DialogTitle>


        <div className={"text_field"}>
          <SelectBox
            label={"Select Stu"}
            required={true}
            value={selectedExStu}
            options={studentsList}
            select={setSelectedExStu}
          />

        </div>

        <div className={"text_field"}>
          <DialogContentText>
            grade: {grade}
          </DialogContentText>
        </div>

        <div className={"text_field"}>
          <DialogContentText>
            Email: {email}
          </DialogContentText>
        </div>

        <div className={"text_field"}>
          <DialogContentText>
            Address: {address}
          </DialogContentText>
        </div>

        <DialogActions>

          <Button
            onClick={() => { handleSave() }}
            color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalWindowAddStu;