import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchLabel=(props)=> {
  
  return(

  <FormControlLabel
  control={<Switch checked={props.checked}  onChange={props.onChange} name="Main course" />}
  label={props.label}
/>
  )
}

export default SwitchLabel;