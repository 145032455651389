import {
  createStore as reduxCreateStore,
  combineReducers,applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {userReducer} from "../users/reducers";
import {classesReducer} from "../classes/reducers";
import {studentsReducer} from '../Students/reducers';
import{studentsEachMonthReducer} from '../StudentsEachmonth/reducer';

export default function createStore(history){
  return reduxCreateStore (
    combineReducers({
      router: connectRouter(history),
      users:userReducer,
      classes:classesReducer,
      students:studentsReducer,
      studentsEachMonth:studentsEachMonthReducer
    }),
applyMiddleware(
    routerMiddleware(history),thunk
    )
   
  )
}


