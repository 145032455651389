import React, { useState, useEffect } from 'react';
import Table from "./Table";
import ModalWindowStudent from '../StudentsBase/ModalWindowStudent';
// import {fetchStudents} from '../../reducks/Students/operations';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { db } from '../../firebase/firebase';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { PButton } from '../../Tools/Index';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const userCount = React.createContext();

//生徒の基本情報表示＆新規生徒追加画面
const StudentsBase = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [count, setCount] = useState(0);
  const [lecs, setLecs] = useState(null);
  const [students, setStudents] = useState(null);
  const [filteredStudents, setFilteredStudents] = useState([]);


  const studentsRef = db.collection("students");
  const classesRef = db.collection("classes");

  const fetchStudents = async () => {
    await studentsRef.orderBy('created_at', 'asc').get()
      .then(snapshots => {
        const studentsList = []
        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          studentsList.push(data)
        })
        setStudents(studentsList)
      })
  }

  const fetchClasses = async () => {
    await classesRef.orderBy('order', 'asc').get()
      .then(snapshots => {
        const classesList = []
        snapshots.forEach(snapshot => {
          const data = snapshot.data();
          classesList.push(data)
        })
        setLecs(classesList);
      })
  }

  // const ChangeAll = async () => {
  //   let newStudents = students.map((stu) => {
  //     if (stu.pickUpSchedule) {
  //       return stu;
  //     } else {
  //       stu.pickUpSchedule = [
  //         { day: "月", pickUp: false, sendBack: false },
  //         { day: "火", pickUp: false, sendBack: false },
  //         { day: "水", pickUp: false, sendBack: false },
  //         { day: "木", pickUp: false, sendBack: false },
  //         { day: "金", pickUp: false, sendBack: false },
  //         { day: "土", pickUp: false, sendBack: false }
  //       ]
  //       return stu;
  //     }
  //   })
  //   newStudents.map(async stu => {
  //     await studentsRef.doc(stu.id).set(stu, { merge: true })
  //   })
  // }

  useEffect(() => {
    fetchStudents();
    fetchClasses();
  }, [])

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      setSearchWord(e.target.value);
    }
  }

  useEffect(() => {
    if (searchWord !== "") {
      let newStudents = [];
      newStudents = students.filter((student) => {
        return (
          student.grade.indexOf(searchWord) !== -1 ||
          student.familyNameJapanese.indexOf(searchWord) !== -1 ||
          student.givenNameJapanese.indexOf(searchWord) !== -1 ||
          student.givenName.toLowerCase().indexOf(searchWord) !== -1 ||
          student.parentName.toLowerCase().indexOf(searchWord) !== -1 ||
          student.course
            .map((c) => c.classname.indexOf(searchWord) !== -1)
            .reduce((prev, current) => (prev || current), 0)
        )
      })
      setFilteredStudents(newStudents);
    } else {
      setFilteredStudents(students);
    }
  }, [searchWord, students])


  if (!lecs) {
    return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
          <p> now loading data... Please Wait.</p>
        </Backdrop>
      </div>
    )
  }


  return (
    <div>
      <h1> Students profile</h1>

      <div className="tyle_arrangement">

        {/* <PButton label={"Change All"}
          variant={"outlined"}
          color={"secondary"}
          onClick={() => ChangeAll()}
        /> */}

        <ModalWindowStudent
          // onDataChange={handleClick}
          label={'Add new Student'}
          title={'Students Resistration Form '}
          allStu={students}
          lecs={lecs}
          setLecs={setLecs}
          setCount={setCount}
          count={count}
          setStudents={setStudents}
        />

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            type="search"
            onKeyDown={handleSearch}
          />
        </div>
        <div className={classes.grow} />

        {(() => {
          if (searchWord === "") {
            return (
              <div>
                <Typography variant="h4" component="h4">
                  Registered Stus : {students.length}
                </Typography>
              </div>
            )

          } else {
            return (
              <Typography variant="h4" component="h4">
                Applicable Stus : {filteredStudents.length}
              </Typography>)
          }
        })()}
      </div>

      <div>
        <Table
          key={count + "table"}
          filteredStudents={filteredStudents}
          allStu={students}
          setAllStu={setStudents}
          lecs={lecs}
        />
      </div>

    </div>
  )
}
export default StudentsBase
