import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { db } from '../../firebase/firebase';

const DeclineNewStu = (props) => {


    const newStudentsRef = db.collection("newStudents")

    const handleClose = () => {
        props.onChange(false);
    };

    const declineStu = async () => {

        const newStusPrev = props.newStudents;

        const newStusNext = newStusPrev.filter((stu) => {
            if (stu.id !== props.declineStu.id) {
                return stu
            }
        })

        console.log(declineStu)
        console.log(newStusNext);

        await newStudentsRef.doc(props.declineStu.id).delete()
        props.setNewStudents(newStusNext)

        handleClose();

    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Student info?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This manupilation can not be redone, if you do not want this just stay away from here. if you really wanna do this, click Delete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        cancel
                    </Button>
                    <Button onClick={() => { declineStu() }} color="secondary" >
                        Decline
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeclineNewStu;