import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { red, green } from '@material-ui/core/colors';
import Image from 'react-image-resizer';


const MenuIcon = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                size="small"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon style={{ fontSize: 'medium' }} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { props.handleClickOpenEmail(props.data) }}>
                    {(() => {
                        if (props.isSent === "undelivered")
                            return (
                                <EmailOutlinedIcon
                                    variant="outlined"
                                    style={{ color: red[500] }}

                                />
                            )
                        else
                            return (
                                <EmailOutlinedIcon
                                    variant="outlined"
                                    style={{ color: green[500] }}
                                />
                            )

                    })()}
                </MenuItem>

                <MenuItem onClick={() => { props.handleClickOpenLine(props.data) }}>
                    <Image src="https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2FLINE_Brand_icon.png?alt=media&token=c70b70d9-0c01-4468-b52b-bc5f1b113fa7"
                        height={20}
                        width={25}
                    />
                </MenuItem>
            </Menu>


        </div>
    );
}

export default MenuIcon; 