import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: 'blue',
    backgroundcolor: "#e6f0f0"
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};



const MultipleSelect = (props) => {
  const classes = useStyles();

  return (
    <div >
      <FormControl className={classes.formControl}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={props.value.map((v) => (v.classname))}
          onChange={(event) => {
            const found = props.options.filter((v) => (
              event.target.value.find((selected) => selected === v.classname)
            ))
            found && props.select(found)
          }}
          input={<Input id="select-multiple-chip" />}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AirportShuttleIcon
                  color="action"
                />
              </InputAdornment>
            )
          }}
          renderValue={(selected) => {
            return (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )
          }}
          MenuProps={MenuProps}
        >
          {props.options.map((data) => (
            <MenuItem key={data.id} label={data.classname} value={data.classname}>
              {data.classname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
  );
}

export default MultipleSelect;