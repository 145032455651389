import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { PButton } from '../../Tools/Index';
import DialogActions from '@material-ui/core/DialogActions';




const RvView = (props) => {

  const [html, setHtml] = useState("");

  const handleClose = () => {
    props.onChange(false);
  };

  const getHtml = async () => {
    //  sendMail() 
    const listId = props.listId;
    const studentId = props.student.id
    const isReciept = true;

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/makeHtmlRV", {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ listId, studentId, isReciept })
    });
    const resData = await response.json();

    return setHtml(resData)
  };

  const DownloadPDF = async () => {
    const listId = props.listId;
    const studentId = props.student.id;
    const isMail = false;
    const isReciept = true;

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/makePdfRv", {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ listId, studentId, isMail, isReciept })
    });
    const resData = await response.blob();

    const d = new Date()
    const month = d.getMonth() + 1;
    const year = d.getFullYear();

    let blob = new Blob([resData], { type: 'application/pdf' })

    const fileName = "RV_" + props.student.gender + props.student.familyName + " " + props.student.givenName + " " + month + "_" + year + ".pdf"

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (props.open === true && props.student.isPaid === "paid") {
    getHtml();
  }

  return (
    <div>


      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth={"xl"}
      >


        <DialogActions>
          <PButton label={"Download PDF"} variant={"outlined"} color="primary" onClick={() => { DownloadPDF() }} />
        </DialogActions>



        <div id="myid" dangerouslySetInnerHTML={{ __html: html }} />


        <div />

      </Dialog>

    </div>
  );
}


export default RvView;

