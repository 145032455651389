import * as Actions from "./action";
import initialState from "../Store/initialState";

export const studentsReducer = (state= initialState.students,action) =>{
  switch(action.type){


      case Actions.FETCH_STUDENTS:
         return{
           ...state,
           list: [...action.payload]
         };

         case Actions.DELETE_STUDENTS:
          return{
            ...state,
            list: [...action.payload]
          };
  


    default:
      return state
  }
}