import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CSVLink } from "react-csv";
import Image from 'react-image-resizer';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CsvOutput = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fetchDoneRef = useRef();
  const [fetchDone, setFetchDone] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);

  const KrungsriImage = "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2Fkrungsri_logo.png?alt=media&token=416bd477-a820-437f-b9be-363e5e897a6f"

  const BangkokImage = "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2FBangkokBank_logo.jpeg?alt=media&token=63ba1782-80f5-4042-81e2-6abea87c1efd"

  const AllImage = "https://firebasestorage.googleapis.com/v0/b/my-redux-app-d8cac.appspot.com/o/src_image%2Fsanaru_logo2.png?alt=media&token=c5c66fff-91e9-42b2-bf33-ac4d184e1215"

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const studentsPaymentMonth = (allStudents, month) => {
    const date = new Date(month);
    const dateMonth = date.getMonth();
    const dateYear = date.getFullYear();

    const tempStu = allStudents.map((data) => {
      const studentsEach = {}
      studentsEach.savedMonth = data.savedMonth;
      const id = data.id
      studentsEach.id = data.id;

      studentsEach.studentsEachMonth = data.studentsEachMonth.filter((stu) => {
        if (stu.paymentDate !== null) {
          const pDate = new Date(stu.paymentDate.seconds * 1000);
          const pDateMonth = pDate.getMonth();
          const pDateYear = pDate.getFullYear();

          return pDateMonth === dateMonth && pDateYear === dateYear;
        }
      })

      studentsEach.studentsEachMonth.forEach((stu) => {
        stu.monthId = id
      })
      return studentsEach
    });

    let tempTempStu = tempStu.map((data) => {
      return data.studentsEachMonth
    }).reduce((prev, current) => {
      return prev.concat(current)
    }, [])

    tempTempStu.sort((prev, current) => {
      if (prev.paymentDate < current.paymentDate) {
        return -1;
      } else {
        return 1;
      }
    })
    return tempTempStu;
  }


  const makeCsvData = (temp, bank, selectedMonth) => {
   const tempTempStu = studentsPaymentMonth(temp,props.selectedMonth);
   console.log(temp,props.selectedMonth,tempTempStu);

    const groupBy = (array, getKey) =>
      array.reduce((obj, cur, idx, src) => {
        const key = getKey(cur, idx, src);
        (obj[key] || (obj[key] = [])).push(cur);
        return obj;
      }, {});

    const groups = groupBy(tempTempStu, r => r.paymentDate);
    const paidStudents = Object.values(groups)
    const monthInNumber=["01","02","03","04","05","06"
     ,"07","08","09","10","11","12"];


    let csvData = paidStudents.map((stu,index) => {
      let name = "";
      const pDate= new Date(stu[0].paymentDate.seconds * 1000 + stu[0].paymentDate.nanoseconds / 1000000)
      const pDateToString = pDate.toLocaleString()
 
      const year = pDate.getYear() - 100;
      const month = monthInNumber[pDate.getMonth()];
      const idData = year + month 
      const rvStartN = 2;
      const RN = ("000"+(index+parseInt(rvStartN))).slice(-3)
      let price = 0;
      let bankName = stu[0].bank.name 
      let RvN = "RV" + idData + RN

      for (let i = 0; i < stu.length; i++) {
        if (stu.length > 1) {
          if(i===0){
            name = stu[i].parentName +" "+stu[i].givenName;
          }else{
            name = name + " & " + stu[i].parentName + stu[i].givenName;
          }
         
          let sum = stu[i].course.reduce((prev, current) => {
            const total = parseInt(current.cPrice)
            if (isNaN(total)) {
              return prev;
            } else {
              return prev + parseInt(current.cPrice);
            }
          }, 0)
          price = price + sum; 
        }else{
          name = stu[i].parentName +" "+stu[i].givenName;
          price = stu[i].course.reduce((prev, current) => {
            const total = parseInt(current.cPrice)
            if (isNaN(total)) {
              return prev;
            } else {
              return prev + parseInt(current.cPrice);
            }
          }, 0)
        }
      }
      return {pDate:pDateToString, name:name,price:price,RV:RvN,bank:bankName}
    })

    if(bank==='Krungsri'){
    let final = csvData.filter((v)=>{
       if(v.bank==="Krungsri"){
         return v
       }
     })
     console.log(bank,final)
      return final 
    }else if(bank === "Bangkok"){
      let final = csvData.filter((v)=>v.bank === 'Bangkok Bank')
      console.log(bank,final)
      return final
    }else{
      console.log(bank,csvData);
      return csvData
    }
  }

  const Output = (students, bank) => {

    const header = [{ label: "PaymentDate", key: "pDate" }, { label: "Name", key: "name" }, { label: "Price", key: "price" }, {label:"RV",key:"RV"},{ label: "Bank", key: "bank" }]

    setCsvHeader(header);

    const fileName = bank + "_data.csv"
    setCsvFileName(fileName);

    const csvData = makeCsvData(students, bank)

    setCsvData(csvData);

    setFetchDone(true);
    console.log(fetchDone);
    fetchDone && fetchDoneRef.current.link.click();
    handleClose();
  }



  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
      >
        OUTPUT CSV
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <Image src={KrungsriImage}
              height={20}
              width={20} />
          </ListItemIcon>
          <ListItemText primary="Krungsri" onClick={() => Output(props.studentsAllMonth, "Krungsri",props.selectedMonth)} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Image src={BangkokImage}
              height={20}
              width={20} />
          </ListItemIcon>
          <ListItemText primary="Bangkok Bank" onClick={() => Output(props.studentsAllMonth, "Bangkok",props.selectedMonth)} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Image src={AllImage}
              height={20}
              width={20} />
          </ListItemIcon>
          <ListItemText primary="All" onClick={() => Output(props.studentsAllMonth, "All",props.selectedMonth)} />
        </StyledMenuItem>
      </StyledMenu>

      {fetchDone && <CSVLink
        headers={csvHeader}
        data={csvData}
        filename={csvFileName} //ファイル名を指定
        ref={fetchDoneRef} // refを指定
      />}


    </div>
  );
}

export default CsvOutput;