import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { db } from "../../firebase/firebase";


const DeleteStu = (props) => {


  const studentsEachMonthRef = db.collection("studentsEachMonth")
  const handleClose = () => {
    props.onChange(false);
  };

  const deleteStu = async () => {

    const allStu = props.allStudents;

    const filteredStudents = props.students;

    const tempAllStus = allStu.filter((stu) => {
      if (stu.id !== props.student.id)
        return stu
    })

    const tempFilteredStu = filteredStudents.filter((stu) => {
      if (stu.id !== props.student.id) {
        return stu
      }
    })

    const monthId = props.student.monthId;
    const stuId = props.student.id

    await studentsEachMonthRef.doc(monthId).collection("students").doc(stuId).delete();

    props.setStu(tempFilteredStu);
    props.setAllStu(tempAllStus);
    handleClose();

  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Student info?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This manupilation can not be redone, if you do not want this just stay away from here. if you really wanna do this, click Delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            cancel
          </Button>
          <Button onClick={() => { deleteStu() }} color="secondary" >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteStu;