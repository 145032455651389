import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Image from 'react-image-resizer';
import '../../Styles/Styles.css';
import UploadPaymentData from "./UploadPaymentData"
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import RecieptPDF from './RecieptPDF';
import RvView from './RvView'
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const StudentsRecieptList = (props) => {
  const classes = useStyles();
  const students = props.value;
  const [openInput, setOpenInput] = useState(false);
  const [openReciept, setOpenReciept] = useState(false);
  const [openRv, setOpenRv] = useState(false);
  const [editStudent, setEditStudent] = useState(null);
  const [checkDouble, setCheckDouble] = useState([]);
  
  const handleClickOpenInput = (data) => {
    setOpenInput(true);
    setEditStudent(data);
  }

  const handleClickOpenReciept = (data) => {
    setOpenReciept(true);
    setEditStudent(data);
  }

  const handleClickOpenRv = (data) => {
    setOpenRv(true);
    setEditStudent(data);
  }

  useEffect(() => {
    

    let doubleCheck = students;
    let check = []
    if ((props.searchWord === "krungsri")) {
       for (let i = 0; i < doubleCheck.length - 1; i++) {
        if (i === 0) {
          if (doubleCheck[i].paymentDate.seconds === doubleCheck[i + 1].paymentDate.seconds) {
            check.push(true);
          } else {
            check.push(false);
          }
        } else {
          if (doubleCheck[i].paymentDate.seconds === doubleCheck[i - 1].paymentDate.seconds || doubleCheck[i].paymentDate.seconds === doubleCheck[i + 1].paymentDate.seconds) {
            check.push(true);
          } else {
            check.push(false);
          }
        }
      }
    }else if(props.searchWord ==="bangkok"){
      for (let i = 0; i < doubleCheck.length - 1; i++) {
        if (i === 0) {
          if (doubleCheck[i].paymentDate.seconds === doubleCheck[i + 1].paymentDate.seconds) {
            check.push(true);
          } else {
            check.push(false);
          }
        } else {
          if (doubleCheck[i].paymentDate.seconds === doubleCheck[i - 1].paymentDate.seconds || doubleCheck[i].paymentDate.seconds === doubleCheck[i + 1].paymentDate.seconds) {
            check.push(true);
          } else {
            check.push(false);
          }
        }
      }

    }
    setCheckDouble(check);
  }, [students])

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Index</StyledTableCell>
              <StyledTableCell align="left">Student</StyledTableCell>
              <StyledTableCell align="left">Tuition Fee</StyledTableCell>
              <StyledTableCell align="left">Paid Date</StyledTableCell>
              <StyledTableCell align="left">Paid Bank</StyledTableCell>
              <StyledTableCell align="left">isPaid</StyledTableCell>
              <StyledTableCell align="left">Slip</StyledTableCell>
              <StyledTableCell align="left">Address</StyledTableCell>
              <StyledTableCell align="left">Input</StyledTableCell>
              <StyledTableCell align="left">P.R.</StyledTableCell>
              <StyledTableCell align="left">P.RV</StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((data, index) => (
              <StyledTableRow key={data.id}>

                <StyledTableCell align="left">
                  {(() => {
                    if (props.searchWord ==='krungsri') {
                      if (checkDouble[index] === true)
                        return <CheckCircleOutlineIcon style={{ color: red[200] }}/>
                      else
                        return <>{index + 1} </>
                    }else if(props.searchWord === 'bangkok'){
                      console.log("bangkok")
                      if (checkDouble[index] === true)
                      return <CheckCircleOutlineIcon style={{ color: red[200] }}/>
                    else
                      return <>{index + 1} </>
                    }else{
                      return <>{index+1}</>
                    }
                  })()}
                </StyledTableCell>

                <StyledTableCell align="left">
                  {data.parentName + "  " + data.givenName}
                </StyledTableCell>

                <StyledTableCell align="left">

                {(() => {
                    if (data.grade !=="クレ") {
                     return(
                  <> {data.course.reduce((prev, current) => {
                    const total = parseInt(current.cPrice)
                    if (isNaN(total)) {
                      return prev;
                    } else {
                      return prev + parseInt(current.cPrice);
                    }
                  }, 0)}</>)
                    }else{
                   if(data.course[1].cPrice !==""){
                    return <>{data.course[1].cPrice}</>
                   }else{
                     return <>0</>
                   }
                    }
                  })()}
                 

                </StyledTableCell>
                <StyledTableCell align="left">
                  {(() => {
                    if (!data.paymentDate)
                      return <></>
                    else
                      return <> {new Date(data.paymentDate.seconds * 1000 + data.paymentDate.nanoseconds / 1000000).toLocaleString()} </>
                  })()}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div className='logo' >
                    <div className='logo-image'>
                      <Image src={data.bank.logo}
                        height={20}
                        width={20}
                      />
                    </div>
                    {data.bank.name}
                    {(() => {
                    if (data.grade === "クレ")
                      return  <p>credit card</p>
                    else
                      return <></>
                  })()}
                  </div>
                </StyledTableCell>

                <StyledTableCell align="left">
                  {(() => {
                    if (data.isPaid === "paid")
                      return <CheckIcon style={{ color: green[200] }} />
                    else
                      return <></>
                  })()}

                </StyledTableCell>

                <StyledTableCell align="left">
                  {(() => {
                    if (data.images.path === "" || data.images.path === null)
                      return <></>
                    else
                      return <CheckIcon style={{ color: green[200] }} />
                  })()}

                </StyledTableCell>

                <StyledTableCell align="left">
                  {data.address}
                </StyledTableCell>


                <StyledTableCell align="left">
                  <AttachFileOutlinedIcon onClick={() => {
                    handleClickOpenInput(data)
                  }} />
                </StyledTableCell>

                <StyledTableCell align="left">
                  <DescriptionOutlinedIcon variant="outlined" onClick={() => { handleClickOpenReciept(data) }} />
                </StyledTableCell>

                <StyledTableCell align="left">
                  <ReceiptOutlinedIcon variant="outlined" onClick={() => { handleClickOpenRv(data) }} />
                </StyledTableCell>


              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UploadPaymentData
        open={openInput}
        onChange={setOpenInput}
        listId={props.listId}
        selectedMonth={props.selectedMonth}
        student={editStudent}
        students={props.allStudents}
      />

      <RecieptPDF
        open={openReciept}
        onChange={setOpenReciept}
        listId={props.listId}
        selectedMonth={props.selectedMonth}
        student={editStudent}
      />

      <RvView
        open={openRv}
        onChange={setOpenRv}
        listId={props.listId}
        selectedMonth={props.selectedMonth}
        student={editStudent} />

    </div>
  );
}

export default StudentsRecieptList;