import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckboxCarSchedule = (props) => {

    const [checked, setChecked] = React.useState([props.data.pickUp, props.data.sendBack]);

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], event.target.checked]);
    };

    useEffect(() => {
        let prev = props.pickUpSchedule;

        prev[props.index] = {
            day: props.data.day,
            pickUp: checked[0],
            sendBack: checked[1]
        }


        let tempPickUp = prev.reduce((prev, cur) => {
            let count = 0
            if (cur.pickUp) count = count + 1
            if (cur.sendBack) count = count + 1

            return prev + parseInt(count)
        }, 0)



        props.setPickUpSchedule(prev);
        props.setPickUp(tempPickUp)

    }, [checked])



    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
                label="行き"
                control={<Checkbox
                    checked={checked[0]}
                    onChange={handleChange2}
                    color="primary"
                />}
            />
            <FormControlLabel
                label="帰り"
                control={<Checkbox
                    checked={checked[1]}
                    onChange={handleChange3}
                    color="primary"
                />}
            />
        </Box>
    );


    return (
        <div>
            <FormControlLabel
                label={props.data.day}
                control={
                    <Checkbox
                        checked={checked[0] && checked[1]}
                        indeterminate={checked[0] !== checked[1]}
                        color="primary"
                        onChange={handleChange1}
                    />
                }
            />
            {children}
        </div>
    );
}

export default CheckboxCarSchedule;