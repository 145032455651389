import React from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import Box from '@material-ui/core/Box';


const Dnd = (props) => {


    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            props.setItems((temp) => {
                const activeIndex = temp.findIndex((temp) => temp.id === active.id);
                const overIndex = temp.findIndex((temp) => temp.id === over.id);
                return arrayMove(temp, activeIndex, overIndex)
            })
        }
    }


    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >

            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                // bgcolor: "blue",
                width: "90%",
                margin: "10px"
            }}>

                <SortableContext
                    items={props.items}

                >
                    {props.items.map(item =>
                        <SortableItem
                            key={item.id}
                            id={item.id}
                            content={item}
                            scaleFactor={props.scaleFactor}
                            setOpenModalClassEdit={props.setOpenModalClassEdit}
                            setSelectedClass={props.setSelectedClass}
                        />
                    )}
                </SortableContext>

            </Box>

        </DndContext>
    )
}

export default Dnd;



