import * as Actions from "./action";
import initialState from "../Store/initialState";

export const classesReducer = (state= initialState.classes,action) =>{
  switch(action.type){
    case Actions.FETCH_CLASSES:
       return{
         ...state,
         list: [...action.payload]
       };

       case Actions.DELETE_CLASSES:
        return{
          ...state,
          list: [...action.payload]
        };


    default:
      return state
  }
}