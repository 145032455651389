export const firebaseConfig = {
  apiKey:process.env.REACT_APP_FIREBASE_APIKEY ,
  authDomain:process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGENG_SENDER_ID,
  appId:process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID
};


// export const firebaseConfig = {
//   apiKey: "AIzaSyAeLZFAofI9ekrnXbiRK6LJg1oVmM9UtmQ",
//   authDomain: "my-redux-app-d8cac.firebaseapp.com",
//   databaseURL: "https://my-redux-app-d8cac.firebaseio.com",
//   projectId: "my-redux-app-d8cac",
//   storageBucket: "my-redux-app-d8cac.appspot.com",
//   messagingSenderId: "753451595492",
//   appId: "1:753451595492:web:740ea59690d10cb66e1ef1",
//   measurementId: "G-LZ26R1392L"
// };

// REACT_APP_FIREBASE_APIKEY=AIzaSyAsVvH1K5lrDmqLFwgKW5uxQS3s_yn5XKs
// REACT_APP_FIREBASE_AUTHDOMAIN=sanaru-19265.firebaseapp.com
// REACT_APP_FIREBASE_DATABASEURL=https://sanaru-19265.firebaseio.com
// REACT_APP_FIREBASE_PROJECT_ID=sanaru-19265
// REACT_APP_FIREBASE_STORAGEBUCKET=sanaru-19265.appspot.com
// REACT_APP_FIREBASE_MESSAGENG_SENDER_ID=178664506618
// REACT_APP_FIREBASE_APP_ID=1:178664506618:web:15ed505c2a0a67b30ef543
// REACT_APP_FIREBASE_APP_MEASUREMENT_ID=G-8ZP7C350LE